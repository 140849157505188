<template>
  <BaseContainer
    class="execution-dash-total-value"
    :permission-list="['owner']"
  >
    <template v-slot:header-title>
      {{ getParseContexTitle }}
    </template>
    <BaseBreadcrumb :config="$route.meta.breadcrumb" />
    <v-chip
      class="ma-2 ml-0 font-weight-bold"
      label
      text-color="primary"
      color="secondary"
    >
      <v-icon
        left
        small
      >
        calendar_today
      </v-icon>
      {{ dateToday | getDate }}
    </v-chip>
    <template v-if="showEmbed">
      <v-row>
        <v-col
          v-for="item, index in getParseContexUrl"
          :key="index"
          cols="12"
          :md="item.layout_cols"
          class="pl-2"
        >
          <h3
            v-if="item.title"
            class="mb-2"
          >
            {{ item.title }}
          </h3>
          <iframe
            width="100%"
            :height="item.layout_rows * 126"
            seamless
            frameBorder="0"
            scrolling="yes"
            :src="getIframeUrl(item)"
          />
        </v-col>
      </v-row>
    </template>
    <div
      v-else
      class="d-flex align-center justify-center text-center mb-4"
      style="height: 300px;"
    >
      <div>
        <img
          src="@/assets/no-itens.svg"
          width="126"
          height="114"
        >
        <p>Nenhum valor para exibir.</p>
      </div>
    </div>
  </BaseContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseBreadcrumb from '../BaseBreadcrumb'

export default {
  components: { BaseBreadcrumb },
  data() {
    return {
      showEmbed: false,
    }
  },
  computed: {
    ...mapState({
      ticket: state => state.ticket,
      programInitiator: state => state.platformProgram.programInitiator,
      programConfiguration: (state) => state.platformProgram.programInfo ? state.platformProgram.programInfo.configuration : null,
    }),
    dateToday() {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    getParseContexTitle(){
      let context = {
        "total_write_off": "Valor baixado",
        "total_amount": "Valor cedido"
      }
      let defaultValue = "Valor cedido"
      return this.$route.query.context ? context[this.$route.query.context] : defaultValue
    },
    getParseContexUrl(){
      let context = null
      let defaultValue = null

      if (this.programConfiguration) {
          context = {
            "total_write_off": this.programConfiguration['dashboard.total_write_off_value'] ? this.programConfiguration['dashboard.total_write_off_value'] : null,
            "total_amount": this.programConfiguration['dashboard.total_value'] ? this.programConfiguration['dashboard.total_value'] : null,
          }
          defaultValue = this.programConfiguration['dashboard.total_value'] ? this.programConfiguration['dashboard.total_value'] : null
      }

      let contextValue = this.$route.query.context ? context[this.$route.query.context] : defaultValue

      if (typeof contextValue === "string") {
        try {
          contextValue = JSON.parse(contextValue)
        } catch (error) {
          contextValue = [{ url: contextValue, title: "", layout_cols: "", layout_rows: "" }]
        }
      }
      if (Array.isArray(contextValue)) {
        return contextValue.map(item =>
          typeof item === "string" ? { url: item, title: "", layout_cols: "", layout_rows: "" } : item
        )
      }

      return []
    }
  },
  async created(){
    if(!this.programInitiator){
      await this.apiGetProgramInfo()
    }
    this.getTicket()
  },
  methods: {
    ...mapActions(["apiGetTicket", "apiGetProgramInfo"]),
    async getTicket(){
      try {
        await this.apiGetTicket({ program_id: this.programInitiator })
        this.showEmbed = true
      } catch (error) {
        this.showEmbed = false
      }
    },
    getIframeUrl(item) {
      let heightDash = 126 * item.layout_rows
      let dashUrl = item.url
      if (dashUrl) {
        let splitUrl = dashUrl.split('?')
        let paramToUrlChart = splitUrl[1] ? `?${splitUrl[1]}` + `&ticket=${this.ticket}&height=${heightDash}` : `?ticket=${this.ticket}&height=${heightDash}`
        let url = splitUrl[0] ? splitUrl[0] + paramToUrlChart : null
        return url
      }
    }
  }
}
</script>

<template>
  <v-snackbar
    :value="activeSnackbar"
    :timeout="configSnackbar.timeout"
    :color="customizeSnackbar.colorBg"
    :width="configSnackbar.width"
    vertical
    @input="closeSnackbar"
  >
    <h3 
      v-if="titleSnackbar"
      style="color: #293273" 
      class="mb-4"
    >
      {{ titleSnackbar }} 
    </h3>
    <div class="app-align-items-center">
      <v-spacer />
      <v-icon
        v-if="iconSnackbar"
        class="mr-1"
        :color="customizeSnackbar.colorTxt"
      >
        {{ customizeSnackbar.icon }}
      </v-icon>
      <span :style="{'color' : customizeSnackbar.colorTxt}">{{ textSnackbar }}</span>
    </div>
    <template v-slot:action>
      <BaseButton
        :type="configSnackbar.typeButton"
        button-class="button-default-style"
        :color="configSnackbar.colorButton"
        @click="handleButtonClick"
      >
        {{ textButtonAction }}
      </BaseButton>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "BaseSnackbar",
  props: {
    activeSnackbar: {
      type: Boolean,
      default: false
    },
    typeSnackbar: {
      type: String,
      default: "default"
    },
    textSnackbar: {
      type: String,
      default: ""
    },
    textButtonAction: {
      type: String,
      default: 'Fechar'
    },
    buttonAction: { 
      type: Function,
      default: () => {
      }
    },
    iconSnackbar: {
      type: Boolean,
      default: true
    },
    titleSnackbar: {
      type: String,
      default: ""
    }
  },
  computed: {
    customizeSnackbar() {
      switch (this.typeSnackbar) {
        case "success":
          return {colorBg: "#00B679", colorTxt: "#FFFFFF", icon: "check_circle"}
        case "error":
          return {colorBg: "#DE3838", colorTxt: "#FFFFFF", icon: "cancel"}
        case "alert":
          return {colorBg: "#FFB44D", colorTxt: "rgba(0, 0, 0, 0.54)", icon: "warning"}
        case "info":
          return {colorBg: "#35A8FA", colorTxt: "#FFFFFF", icon: "error"}
        case "infoPlatform": 
          return {colorBg: "#FFFFFF", colorTxt: "#000", icon: "info"}
        default:
          return {colorBg: "#EBECF0", colorTxt: "rgba(0, 0, 0, 0.54)", icon: "error"}
      }
    },

    configSnackbar(){
      if(this.typeSnackbar === 'infoPlatform'){
        return {
          timeout: -1,
          width: 700,
          typeButton: 'depressed',
          colorButton: 'primary'
        }
      } else {
        return {
          timeout: 6000,
          width: 340,
          typeButton: 'text',
          colorButton: this.customizeSnackbar.colorTxt
        }
      }
    }
  },
  methods: {
    closeSnackbar() {
      this.$emit("closeSnackbar", false)
    },

    handleButtonClick(){
      if(this.typeSnackbar === "infoPlatform"){
        this.buttonAction()
      } else {
        this.closeSnackbar()
      }
    }
  }
}
</script>

<template>
  <div class="step-program-configuration">
    <h2 class="font-weight-bold title mb-1 mt-6 color--text-black-054 text-center">
      Configurações
    </h2>
    <p class="mb-12 color--text-black-054 body-2">
      Defina configurações de negociação, menus para serem exibidos e dashboard do programa.
    </p>

    <v-form
      v-model="formValid"
    >
      <v-col
        sm="12"
        class="pa-0 pl-2 mb-10"
      >
        <template v-if="platformDynamicFields.length">
          <div
            v-for="field in platformDynamicFields"
            :key="field.id"
            class="px-6"
          >
            <template v-if="field.input_type == 'boolean'">
              <v-checkbox
                :input-value="parseBooleanValue(programConfiguration[parseFieldKey(field.key)])"
                :label="getLabelField(field)"
                :disabled="disableField(field)"
                :rules="validateField(field.required)"
                :qa-identification-name="`qa-id-name-${field.key}`"
                :hint="field.help_text"
                :persistent-hint="field.help_text ? true : false"
                class="my-6"
                @change="changeCheckbox(field.key, parseBooleanValue(programConfiguration[parseFieldKey(field.key)]))"
              />
            </template>
            <template v-else-if="field.input_type == 'text'">
              <BaseInput
                v-model="programConfiguration[parseFieldKey(field.key)]"
                :label="getLabelField(field)"
                :disabled="disableField(field)"
                :rules="validateField(field.required)"
                :qa-identification-name="`qa-id-name-${field.key}`"
                :hint="field.help_text"
                :persistent-hint="field.help_text ? true : false"
                class="my-6"
              />
            </template>
            <template v-else-if="field.input_type == 'select'">
              <v-select
                v-model="programConfiguration[parseFieldKey(field.key)]"
                :items="getOptionsList(field)"
                item-text="name"
                item-value="value"
                :label="getLabelField(field)"
                :disabled="disableField(field)"
                :rules="validateField(field.required)"
                :qa-identification-name="`qa-id-name-${field.key}`"
                :hint="field.help_text"
                :persistent-hint="field.help_text ? true : false"
                class="my-6"
              />
            </template>
            <template v-else-if="field.input_type == 'object'">
              <div
                :class="{ 'mb-4': !valueIsString(parseFieldKey(field.key), programConfiguration) }"
                :style="{ 'background-color': valueIsString(parseFieldKey(field.key), programConfiguration) ? 'unset' : 'var(--v-grayf8-base)'}"
                class="my-10"
              >
                <BaseInput
                  v-if="valueIsString(parseFieldKey(field.key), programConfiguration)"
                  v-model="programConfiguration[parseFieldKey(field.key)]"
                  dense
                  :label="getLabelField(field)"
                  :hint="field.help_text"
                  :persistent-hint="field.help_text ? true : false"
                >
                  <template v-slot:append-outer>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <BaseButton
                          :has-icon="true"
                          color="primary"
                          class="ma-0 pa-0"
                          v-on="on"
                          @click="changeFieldType(parseFieldKey(field.key))"
                        >
                          <v-icon small>
                            mdi-swap-horizontal
                          </v-icon>
                        </BaseButton>
                      </template>
                      Alterar campo para objeto
                    </v-tooltip>
                  </template>
                </BaseInput>
                <template v-else>
                  {{ getLabelField(field) }}

                  <div class="app-align-items-center my-8">
                    <BaseInput
                      v-for="(key, index) in parseJsonValue(field.datasource).keys"
                      :key="index"
                      v-model="programConfiguration[parseFieldKey(field.key)][key]"
                      :label="key"
                      style="width: 100%;"
                      class="mr-2 text-capitalize"
                      hide-details
                      dense
                      clearable
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <BaseButton
                          :has-icon="true"
                          color="primary"
                          class="ma-0 pa-0"
                          v-on="on"
                          @click="changeFieldType(parseFieldKey(field.key))"
                        >
                          <v-icon small>
                            mdi-swap-horizontal
                          </v-icon>
                        </BaseButton>
                      </template>
                      Alterar campo para string
                    </v-tooltip>
                  </div>
                  <v-divider />
                </template>
              </div>
            </template>
            <template v-else-if="field.input_type == 'object_list'">
              <div
                :class="{ 'mb-4': !valueIsString(parseFieldKey(field.key), programConfiguration) }"
                :style="{ 'background-color': valueIsString(parseFieldKey(field.key), programConfiguration) ? 'unset' : 'var(--v-grayf8-base)'}"
                class="my-10"
              >
                <BaseInput
                  v-if="valueIsString(parseFieldKey(field.key), programConfiguration)"
                  v-model="programConfiguration[parseFieldKey(field.key)]"
                  dense
                  :label="getLabelField(field)"
                  :hint="field.help_text"
                  :persistent-hint="field.help_text ? true : false"
                >
                  <template v-slot:append-outer>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <BaseButton
                          :has-icon="true"
                          color="primary"
                          class="ma-0 pa-0"
                          v-on="on"
                          @click="changeFieldType(parseFieldKey(field.key))"
                        >
                          <v-icon small>
                            mdi-swap-horizontal
                          </v-icon>
                        </BaseButton>
                      </template>
                      Alterar campo para array
                    </v-tooltip>
                  </template>
                </BaseInput>
                <template v-else>
                  <v-autocomplete
                    v-model="programConfiguration[parseFieldKey(field.key)]"
                    :items="programConfiguration[parseFieldKey(field.key)]"
                    :label="getLabelField(field)"
                    :item-text="parseJsonValue(field.datasource).keys[0]"
                    :item-value="parseJsonValue(field.datasource).keys[1]"
                    hint="Adicione itens através dos campos abaixo"
                    persistent-hint
                    return-object
                    multiple
                    chips
                    small-chips
                  >
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <BaseButton
                            :has-icon="true"
                            color="primary"
                            class="ma-0 pa-0"
                            v-on="on"
                            @click="changeFieldType(parseFieldKey(field.key))"
                          >
                            <v-icon small>
                              mdi-swap-horizontal
                            </v-icon>
                          </BaseButton>
                        </template>
                        Alterar campo para string
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <div class="app-align-items-center my-8">
                    <BaseInput
                      v-for="(key, index) in parseJsonValue(field.datasource).keys"
                      :key="index"
                      :ref="`form-${parseFieldKey(field.key)}-${key}`"
                      v-model="datasourceObject[parseFieldKey(field.key)][key]"
                      :label="key"
                      style="width: 100%;"
                      class="mr-2 text-capitalize"
                      hide-details
                      dense
                      clearable
                    />

                    <BaseButton
                      color="primary"
                      class="ma-0"
                      :has-icon="true"
                      @click="addFieldItem(datasourceObject[parseFieldKey(field.key)], parseFieldKey(field.key), parseJsonValue(field.datasource).keys)"
                    >
                      <v-icon small>
                        mdi-plus-box
                      </v-icon>
                    </BaseButton>
                  </div>
                  <v-divider />
                </template>
              </div>
            </template>
            <template v-else>
              <BaseInput
                v-model="programConfiguration[parseFieldKey(field.key)]"
                :label="getLabelField(field)"
                :disabled="disableField(field)"
                :rules="validateField(field.required)"
                :qa-identification-name="`qa-id-name-${field.key}`"
                :hint="field.help_text"
                :persistent-hint="field.help_text ? true : false"
                class="my-6"
              />
            </template>
          </div>
        </template>
        <div
          v-else
          class="d-flex align-center justify-center text-center mb-4"
          style="height: 100px;"
        >
          Nenhum campo para exibir.
        </div>
      </v-col>
    </v-form>
    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import permissions from '../../../mixins/permissions'
import BaseActionFooter from '../../BaseActionFooter'
import ProgramRoles from '@/mixins/ProgramRoles'

export default {
  name: 'StepProgramConfiguration',
  components: { BaseActionFooter },
  mixins: [ permissions, ProgramRoles ],
  data(){
    return {
      programConfiguration: {
      },
      formValid: false,
      backButton: {
        action: () => {
          this.$emit("onPreviousStep", 5)
        },
        text: 'Voltar'
      },
      cancelButton: {
        action: () => {
          this.$router.push('/platform-home')
        },
        text: 'Cancelar',
      },
      isEditingProgram: false,
      datasourceObject: {},
      scopeSchema: 'program-configuration'
    }
  },
  computed: {
    ...mapState({
      programId: (state) => {
        return state.platformProgram.programId
      },
      selectedProgram: (state) => {
        return state.platformProgram.selectedProgram
      },
      platformDynamicFields: state => state.platformProgram.platformDynamicFields,
      suggestions: state => state.programFilters.suggestions,
      programList: (state) => state.platformProgram.allPrograms
    }),
    confirmButton(){
      return {
        action: this.nextStep,
        text: 'Avançar',
        disabled: !this.formValid
      }
    }
  },
  async created(){
    if(!this.selectedProgram){
      await this.apiGetAllPrograms()
      let program = this.programList.filter(elem => elem.id === this.programId)[0]
      this.setSelectedProgram(program)
    }

    if(this.selectedProgram && this.selectedProgram.configuration){
      this.programConfiguration = JSON.parse(JSON.stringify(this.selectedProgram.configuration))
      await this.apiGetPlatformSchemas(this.scopeSchema)
    }
    this.setInitialData()
  },
  methods: {
    ...mapActions(['apiGetPlatformSchemas', 'apiUpdateProgramConfigurations', 'apiGetAllPrograms']),
    ...mapMutations(['setSelectedProgram']),
    setInitialData(){
      Object.keys(this.programConfiguration).forEach(key => {
        if(this.valueIsString(key, this.programConfiguration)){
          if(this.programConfiguration[key].startsWith('[')){
            this.programConfiguration[key] = JSON.parse(this.programConfiguration[key])
            this.datasourceObject[key] = {}
          } else if(this.programConfiguration[key].startsWith('{')){
            this.programConfiguration[key] = JSON.parse(this.programConfiguration[key])
          }
        }
      })
    },
    getOptionsList(field){
      let optionsList = {
        "/v1/programs/roles": this.getRoleOrganizations(),
        "/v1/movements/assets/suggestions/values": this.suggestions
      }
      if(field.datasource.startsWith('[')){
        return JSON.parse(field.datasource)
      } else {
        return optionsList[field.datasource]
      }
    },
    getLabelField(field){
      return field.required == "true" ? `${field.name} *` : field.name
    },
    disableField(asset){
      return asset.read_only == 'true'
    },
    validateField(value){
      if(value == "true")
      return [this.isValid]
    },
    parseFieldKey(key){
      return key.replace('configuration.', '')
    },
    parseBooleanValue(value){
      if(value === "false" || value === false)
      return false
      if(value === "true" || value === true)
      return true
    },
    parseJsonValue(value){
      return JSON.parse(value)
    },
    parseStringValue(value){
      return JSON.stringify(value)
    },
    valueIsString(key, object){
       return typeof object[key] === 'string'
    },
    changeFieldType(key){
      if(this.valueIsString(key, this.programConfiguration)){
        this.programConfiguration[key] = this.programConfiguration[key] ? JSON.parse(this.programConfiguration[key]) : []
      } else {
        this.programConfiguration[key] = this.programConfiguration[key] ? JSON.stringify(this.programConfiguration[key]) : ''
      }
    },
    addFieldItem(value, key, keyList){
      this.programConfiguration[key].push(value)

      this.$nextTick(() => {
        this.$set(this.datasourceObject, key, {})

        keyList.forEach(elem => {
          this.$refs[`form-${key}-${elem}`][0].$el.getElementsByTagName('input')[0].value = ''
        });

        this.$forceUpdate()
      })
    },
    changeCheckbox(key, value){
      this.programConfiguration[this.parseFieldKey(key)] = !value
    },
    async nextStep(){
      let payloadObject = {}

      this.platformDynamicFields.forEach(field => {
        if(field.input_type === 'object_list' || field.input_type === 'object'){
          payloadObject = {
            ...payloadObject,
            [`config.${this.parseFieldKey(field.key)}`]: this.programConfiguration[this.parseFieldKey(field.key)] == undefined ? '' : this.parseStringValue(this.programConfiguration[this.parseFieldKey(field.key)])
          }
        } else if(field.input_type === 'boolean'){
          payloadObject = {
            ...payloadObject,
            [`config.${this.parseFieldKey(field.key)}`]: this.programConfiguration[this.parseFieldKey(field.key)] === 'true' || this.programConfiguration[this.parseFieldKey(field.key)] == true ? 'true' : 'false'
          }
        } else {
          payloadObject = {
            ...payloadObject,
            [`config.${this.parseFieldKey(field.key)}`]: this.programConfiguration[this.parseFieldKey(field.key)] == undefined ? '' : this.programConfiguration[this.parseFieldKey(field.key)]
          }
        }
      });

      try {
        await this.apiUpdateProgramConfigurations({ programId: this.programId, data: payloadObject })
        this.$router.push('/platform-home')
      } catch (error) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao salvar schema.',
          color: 'error',
        })
      }
    }
  },
}
</script>

<style scoped>
.step-program-configuration {
  width: 560px;
  margin: 0 auto;
}

.step-name-configuration__title {
  font-size: 18px;
}

.step-name-configuration_combobox >>> .v-input__icon {
  display: none!important;
}
</style>

<template>
  <BaseCard
    :has-divider="true"
    :has-main-container="true"
    base-card-title-class="app-font-semibold title mb-1"
  >
    <template v-slot:header-title>
      Regras
    </template>
    <template v-slot:header-actions>
      <v-dialog
        :value="isModalActive"
        persistent
        transition="app-transition-to-right"
        content-class="app-transition-to-right-additional-configs"
        @input="updateModalActive"
      >
        <template v-slot:activator="{ on }">
          <BaseButton
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
            v-on="on"
          >
            Testar
          </BaseButton>
        </template>
        <BaseModal
          style="height: 100vh; flex-wrap: nowrap;"
          body-background-color="#F8F8F8"
          :show-indeterminate-progress="modalTestRules.showIndeterminateProgress"
          :top-slot-content-title="modalTestRules.topSlotContentTitle"
          :remove-button-close="modalTestRules.removeButtonClose"
          :middle-slot-content-text-highlight="modalTestRules.middleSlotContentTextHighlight"
          :middle-slot-content-text="modalTestRules.middleSlotContentText"
          :middle-check-agree="modalTestRules.middleCheckAgree"
          :bottom-buttons-align="modalTestRules.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="modalTestRules.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="modalTestRules.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="modalTestRules.removeButtonCancel"
          :remove-button-confirm="modalTestRules.removeButtonConfirm"
          @on-cancel="modalTestRules.onCancel"
          @on-confirm="() => null"
        >
          <v-container class="pa-10">
            <v-row dense>
              <h2>Entidades</h2>
              <v-spacer />
              <BaseButton
                color="primary"
                type="text"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover"
                @click="clearCache()"
              >
                Limpar cache
              </BaseButton>
              <BaseButton
                type="depressed"
                color="primary"
                @click="simulateRule"
              >
                Executar teste
              </BaseButton>
            </v-row>
            <v-tabs
              v-model="tabIndex"
              background-color="transparent"
              class="mt-4"
            >
              <v-tab
                v-for="(tab, index) in tabsList"
                :key="tab"
                :disabled="isEditingTab.some((isEditing, i) => isEditing && i !== index)"
              >
                {{ tab }}
              </v-tab>
              <v-spacer />
              <v-tab class="font-weight-bold">
                Resultado
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tabIndex"
              class="tab-details"
            >
              <BaseCard
                :has-main-container="true"
                color="transparent"
              >
                <v-row>
                  <v-spacer />
                  <BaseButton
                    v-if="tabIndex !== 4"
                    type="text"
                    color="blueff"
                    class="app-button__link-text app-button--hide-ripple app-button--no-hover"
                    :is-disabled="isEditingTab[tabIndex]"
                    @click="editForm(tabIndex)"
                  >
                    Editar
                  </BaseButton>
                </v-row>
                <v-tab-item>
                  <v-card
                    color="transparent"
                    :elevation="0"
                  >
                    <v-card-text>
                      <v-row dense>
                        <BaseInput
                          v-model="state.input.schema"
                          label="Schema"
                          :disabled="!isEditingTab[tabIndex]"
                          clearable
                        />
                      </v-row>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          Options
                          <codemirror
                            v-model="state.input.options"
                            :style="{ 'opacity': !isEditingTab[tabIndex] ? '0.7' : 'unset' }"
                            :options="{ ...cmOptionsEdit, readOnly: !isEditingTab[tabIndex] ? 'nocursor' : false }"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          Payload
                          <codemirror
                            v-model="state.input.payload"
                            :style="{ 'opacity': !isEditingTab[tabIndex] ? '0.7' : 'unset' }"
                            :options="{ ...cmOptionsEdit, readOnly: !isEditingTab[tabIndex] ? 'nocursor' : false }"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    color="transparent"
                    :elevation="0"
                  >
                    <v-card-text>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          Payload
                          <codemirror
                            v-model="state.sharedData.payload"
                            :style="{ 'opacity': !isEditingTab[tabIndex] ? '0.7' : 'unset' }"
                            :options="{ ...cmOptionsEdit, readOnly: !isEditingTab[tabIndex] ? 'nocursor' : false }"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    color="transparent"
                    :elevation="0"
                  >
                    <v-card-text>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          Payload
                          <codemirror
                            v-model="state.balances.payload"
                            :style="{ 'opacity': !isEditingTab[tabIndex] ? '0.7' : 'unset' }"
                            :options="{ ...cmOptionsEdit, readOnly: !isEditingTab[tabIndex] ? 'nocursor' : false }"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    color="transparent"
                    :elevation="0"
                  >
                    <v-card-text>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          Payload
                          <codemirror
                            v-model="state.forecasts.payload"
                            :style="{ 'opacity': !isEditingTab[tabIndex] ? '0.7' : 'unset' }"
                            :options="{ ...cmOptionsEdit, readOnly: !isEditingTab[tabIndex] ? 'nocursor' : false }"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    color="transparent"
                    :elevation="0"
                  >
                    <v-card-text>
                      <v-row
                        dense
                      >
                        <v-col sm="12">
                          <codemirror
                            v-model="formattedOutput"
                            :options="cmOptions"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-row
                  dense
                  justify="end"
                  class="mt-2 mx-3"
                >
                  <v-col cols="auto">
                    <BaseButton
                      v-if="tabIndex !== 4"
                      color="primary"
                      type="text"
                      :is-disabled="!isEditingTab[tabIndex]"
                      @click="cancelForm(tabIndex)"
                    >
                      Cancelar
                    </BaseButton>
                    <BaseButton
                      v-if="tabIndex !== 4"
                      data-submit
                      type="depressed"
                      color="primary"
                      :is-disabled="!isEditingTab[tabIndex]"
                      @click="sendForm(tabIndex)"
                    >
                      Salvar
                    </BaseButton>
                    <BaseButton
                      v-if="tabIndex == 4"
                      data-submit
                      type="depressed"
                      color="primary"
                      :is-disabled="!formattedOutput"
                      @click="clearFieldResult"
                    >
                      Limpar
                    </BaseButton>
                  </v-col>
                </v-row>
              </BaseCard>
            </v-tabs-items>
          </v-container>
        </BaseModal>
      </v-dialog>
    </template>
    <v-row class="mt-4">
      <v-col sm="3">
        <v-select
          v-model="filter.scope"
          :items="scopes"
          clearable
          label="Scope"
        />
      </v-col>
      <v-col
        v-if="filter.scope !== 'system'"
        sm="3"
      >
        <v-select
          v-if="filter.scope === 'program'"
          v-model="filter.owner"
          :items="getInitiators"
          clearable
          label="Owner"
        />
        <BaseInput
          v-else
          v-model="filter.owner"
          label="Owner"
          clearable
        />
      </v-col>
      <v-col sm="2">
        <BaseInput
          v-model="filter.event_type"
          label="EventType"
          clearable
        />
      </v-col>
      <v-col sm="2">
        <BaseInput
          v-model="filter.event_context"
          label="EventContext"
          clearable
        />
      </v-col>
      <v-col
        sm="2"
        class="app-align-items-center"
      >
        <BaseButton
          type="text"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
          @click="filterRules"
        >
          Filtrar
        </BaseButton>
      </v-col>
    </v-row>
    <BaseDataTable
      :items="rulesList"
      :headers="headers"
      :hide-actions="false"
      :has-infinite-pagination="true"
      :options="pagination"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="value in scopedItems"
          :key="value.index"
        >
          <td>
            {{ value.item.name }}
          </td>

          <td
            class="text-right"
          >
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              @click="detailsRule(value.item)"
            >
              Exibir
            </BaseButton>
          </td>
        </tr>
      </template>
    </BaseDataTable>
    <v-dialog
      v-model="modalDetailsRuleActive"
      max-width="1000"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="modalDetailsRule.showIndeterminateProgress"
        :top-slot-content-title="modalDetailsRule.topSlotContentTitle"
        :remove-button-close="modalDetailsRule.removeButtonClose"
        :middle-slot-content-text-highlight="modalDetailsRule.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalDetailsRule.middleSlotContentText"
        :bottom-buttons-align="modalDetailsRule.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalDetailsRule.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalDetailsRule.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalDetailsRule.removeButtonCancel"
        :remove-button-confirm="modalDetailsRule.removeButtonConfirm"
        @on-cancel="() => modalDetailsRuleActive=false"
        @on-confirm="() => modalDetailsRule.onConfirm()"
      >
        <BaseCard
          :has-main-container="true"
          color="transparent"
          style="border: none;"
        >
          <v-card
            color="transparent"
            :elevation="0"
          >
            <v-card-text>
              <v-row
                v-if="rule.conditions"
                dense
              >
                <v-col sm="12">
                  Conditions
                  <codemirror
                    ref="refcodemiror1"
                    v-model="rule.conditions"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="rule.schema"
                dense
              >
                <v-col sm="12">
                  Schema
                  <codemirror
                    ref="refcodemiror2"
                    v-model="rule.schema"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="rule.options"
                dense
              >
                <v-col sm="12">
                  Options
                  <codemirror
                    ref="refcodemiror3"
                    v-model="rule.options"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="rule.then"
                dense
              >
                <v-col sm="12">
                  Then
                  <codemirror
                    ref="refcodemiror4"
                    v-model="rule.then"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </BaseCard>
      </BaseModal>
    </v-dialog>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </BaseCard>
</template>

<script>
import BaseModal from '../../BaseModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'
import BaseDataTable from '../../BaseDataTable'
import { codemirror } from 'vue-codemirror'

export default {
  components: { BaseModal, BaseDataTable, BaseSnackbar, codemirror },
  mixins: [ BaseSnackbarConfiguration ],
  data () {
    return {
      scopes: [
        'account',
        'program',
        'system'
      ],
      filter: {
        scope: 'program',
        owner: 'AGROANTECIPA',
        event_type: 'onExecute',
        event_context: 'movement-action'
      },
      headers: [
        { text: "", value: "name" },
        { text: "", value: "actions", sortable: false}
      ],
      pagination: {
        page: 1,
        itemsPerPage: 25
      },
      isModalActive: false,
      modalTestRules: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Testar regra",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.closeModal,
        onConfirm: null
      },
      modalDetailsRuleActive: false,
      modalDetailsRule: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Detalhes da regra",
        removeButtonClose: false,
        middleSlotContentTextHighlight: '',
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: '',
        removeButtonCancel: false,
        removeButtonConfirm: true,
        onCancel: this.closeModalDetails,
        onConfirm: null
      },
      rule: {
        name: '',
        conditions: [],
        then: [],
        when: [],
        schema: '',
        options: ''
      },
      cmOptionsEdit: {
        tabSize: 4,
        mode: 'groovy',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
      },
      cmOptions: {
        tabSize: 4,
        mode: 'groovy',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
        readOnly: 'nocursor'
      },
      tabIndex: 0,
      tabsList: ['Input', 'Shared Data', 'Balances', 'Forecasts'],
      isEditingTab: [false, false, false, false],
      formattedOutput: '',
      state: {
        movements: [],
        balances: [{
          schema: "accounts/AccountBalance",
          options: {},
          payload: ""
        }],
        forecasts: [{
          schema: "accounts/ForecastBalance",
          options: {},
          payload: ""
        }],
        sharedData: [{
          schema: "",
          options: {},
          payload: ""
        }],
        input: {
          schema: "movements/Movement",
          options: "{}",
          payload: "{}"
        },
        target_rule: {
          owner: "",
          scope: "",
          event_type: "",
          event_context: ""
        }
      }
    }
  },

  computed: {
    ...mapGetters(['getInitiators']),
    ...mapState({
        rulesList: state => state.common.rulesList
    }),
  },

  watch: {
    modalDetailsRuleActive(value){
      if(value){
        setTimeout(() => {
          Object.keys(this.$refs).forEach((ref) => {
            if(ref.includes('refcodemiror')){
              let codemirror = this.$refs[ref].$el.querySelector('.CodeMirror')
              codemirror.CodeMirror.refresh()
            }
          })
        }, 300);
      }
    }
  },

  async created() {
    await this.apiGetInitiators()
    this.getRules()
  },

  methods: {
    ...mapActions(['apiGetInitiators', 'apiGetRules', 'apiSimulateTest', 'apiClearCache']),

    async getRules(){
      let payload  = {
        ...this.filter,
      }
      await this.apiGetRules(payload)
    },

    updateModalActive(value) {
      this.isModalActive = value
    },

    async clearCache(){
      try {
        let payload = {
          owner: this.filter.owner,
          scope: this.filter.scope
        }

        await this.apiClearCache(payload)
        this.showFeedback({
          color: "success",
          message: 'Cache limpo com sucesso!',
        })
      } catch (e) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao limpar cache.',
        })
      }
    },


    async simulateRule() {
      try {
        const payload = {
        state: {
          movements: this.state.movements,
          balances: this.state.balances
            .filter(balances => balances.payload && balances.payload.trim() !== "")
            .map(balances => ({
              ...balances,
              payload: balances.payload,
            })),
          forecasts: this.state.forecasts
            .filter(forecasts => forecasts.payload && forecasts.payload.trim() !== "")
            .map(forecasts => ({
              ...forecasts,
              payload: forecasts.payload,
            })),
          sharedData: this.state.sharedData.map(shared => ({
            ...shared,
            payload: shared.payload
          })),
        },
        target_rule: {
          owner: this.filter.owner,
          scope: this.filter.scope,
          event_type: this.filter.event_type,
          event_context: this.filter.event_context,
        },
        input: {
          schema: this.state.input.schema,
          options: JSON.parse(this.state.input.options || "{}"),
          payload: this.state.input.payload,
        },
      }
      const responseAPI = await this.apiSimulateTest(payload)
      this.formattedResult(responseAPI)

      this.showFeedback({
        color: "success",
        message: 'Sucesso ao testar a regra!',
      })

      } catch (e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao testar a regra!',
        })
      } finally {
        this.tabIndex = 4
      }
    },

    formattedResult(responseAPI){
      const newState = responseAPI.data.state || {}
       const formattedOutput =
       `==== LOG SAÍDA ====\n\n${responseAPI.data.output || "Sem saída"}\n\n==== newState ====
        ${JSON.stringify(
          {
            sharedData: newState.sharedData.map(data => JSON.parse(data.payload || "{}")) || [],
            balances: newState.balances || [],
            movements: newState.movements || [],
            forecasts: newState.forecasts || [],
          },
          null,
          2
        )}
      `

      this.formattedOutput = formattedOutput
    },

    editForm(tabIndex){
      this.$set(this.isEditingTab, tabIndex, true)
    },

    sendForm(tabIndex) {
      switch(this.tabIndex) {
        case 0:
          this.state.input.schema = this.state.input.schema
          this.state.input.options = this.state.input.options
          this.state.input.payload = this.state.input.payload
          this.$set(this.isEditingTab, tabIndex, false)
          this.tabIndex++
          break
        case 1:
          this.state.sharedData[0].payload = this.state.sharedData[0].payload
          this.$set(this.isEditingTab, tabIndex, false)
          this.tabIndex++
          break
        case 2:
          this.state.balances[0].payload = this.state.balances[0].payload
          this.$set(this.isEditingTab, tabIndex, false)
          this.tabIndex++
          break
        case 3:
          this.state.forecasts[0].payload = this.state.forecasts[0].payload
          this.$set(this.isEditingTab, tabIndex, false)
          this.tabIndex++
          break
      }
    },

    cancelForm(tabIndex){
      switch(this.tabIndex) {
        case 0:
          this.state.input.schema = ''
          this.state.input.options = ''
          this.state.input.payload = ''
          this.$set(this.isEditingTab, tabIndex, false)
          break
        case 1:
          this.state.sharedData.payload = ''
          this.$set(this.isEditingTab, tabIndex, false)
          break
        case 2:
          this.state.balances.payload = ''
          this.$set(this.isEditingTab, tabIndex, false)
          break
        case 3:
          this.state.forecasts.payload = ''
          this.$set(this.isEditingTab, tabIndex, false)
          break
      }
    },

    clearFieldResult(){
      try {
        this.formattedOutput = ''
        this.tabIndex = 0
        this.showFeedback({
          color: "success",
          message: 'Campo de resultado limpo com sucesso!',
        })
      } catch (e) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao limpar campo de resultado',
        })
      }

    },

    detailsRule(value) {
      this.modalDetailsRuleActive = true
      this.modalDetailsRule.topSlotContentTitle = value.name

      this.rule.conditions = value.conditions ? value.conditions.join('\n') : ''
      this.rule.then = value.then ? value.then.join('\n') : ''
      this.rule.schema = value.schema ? value.schema.join('\n') : ''
      this.rule.options = value.options ? value.options.join('\n') : ''
    },

    filterRules(){
      this.getRules()
    },

    closeModal(){
      this.isModalActive = false
    },

    closeModalDetails() {
      this.modalDetailsRuleActive = false
    }
  }
}
</script>

<style scoped>

div >>> .CodeMirror.cm-s-base16-dark {
  height: 250px !important;
}

.tab-details {
  background-color: #F8F8F8!important;
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

</style>

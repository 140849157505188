<template>
  <BaseContainer
    class="execution-detail"
    :permission-list="['owner']"
  >
    <template v-slot:header-title>
      {{ getCurrentExecuteItem.data && getCurrentExecuteItem.data.name ? getCurrentExecuteItem.data.name : getCurrentExecuteItem.description }}
    </template>
    <BaseBreadcrumb :config="$route.meta.breadcrumb" />
    <h3 class="app-headline app-font-semibold mb-2">
      Detalhes da operação
    </h3>
    <v-row>
      <v-col
        sm="6"
        class="pl-0"
      >
        <p>
          Etapa: <span class="primary--text font-weight-bold">{{ getExecutionDetailParseArray[getCurrentStep].name }}</span>
        </p>
      </v-col>
      <v-col
        sm="6"
        class="pl-0"
      >
        <p class="mb-4">
          Status:
        </p>
        <v-progress-linear
          :value="getStatusProgress.value"
          :color="getStepColor(getStatusProgress.status)"
          rounded
          height="25"
        >
          <template v-slot:default="{ value }">
            <p>{{ getStepText(getStatusProgress.status) }}</p>
            <p>{{ Math.ceil(value) }}%</p>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-divider class="my-10" />

    <v-sheet
      class="mx-auto"
      max-width="100%"
      color="transparent"
    >
      <v-slide-group
        v-model="selectedStepModel"
        class="pa-4"
        show-arrows
        center-active
      >
        <v-stepper
          alt-labels
          non-linear
          editable
        >
          <v-stepper-header>
            <v-slide-item
              v-for="step,index in getExecutionDetailParseArray"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <div class="app-align-items-center">
                <v-stepper-step
                  :key="`${index}-stepper`"
                  :step="index + 1"
                  :color="getStepColor(step.status)"
                  :complete="step.status === 'DONE'"
                  :error-icon="getStepIcon(step.status)"
                  :rules="[() => step.status === 'DONE' || step.status === 'PENDING']"
                  :class="getClassSteper(step, active)"
                  @click="toggle"
                >
                  {{ step.name }}
                  <small
                    v-if="step.updatedAt"
                    :color="getStepColor(step.status)"
                    class="mt-1"
                  >{{ step.updatedAt | getDate }} - {{ step.updatedAt | getTime }}</small>
                </v-stepper-step>
                <div
                  v-if="(index + 1) < getExecutionDetailParseArray.length"
                  style="border-top: 1px solid #a7a4a4; min-width: 60px;"
                />
              </div>
            </v-slide-item>
          </v-stepper-header>
        </v-stepper>
      </v-slide-group>

      <v-expand-transition v-if="false">
        <v-sheet
          v-if="selectedStepModel != null"
          height="200"
          class="pa-4"
          tile
        >
          <h3 class="text-h6">
            Etapa: {{ getExecutionDetailParseArray[selectedStepModel].name }}
          </h3>
        </v-sheet>
      </v-expand-transition>
    </v-sheet>
  </BaseContainer>
</template>

<script>
import { mapState } from 'vuex';
import BaseBreadcrumb from '../BaseBreadcrumb'
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: 'ExecutionDetail',
  components: { BaseBreadcrumb },
  data(){
    return {
      selectedStepModel: null
    }
  },
  computed: {
    ...mapState({
      executionsProgram: (state) => state.operationInfo.executionsProgram
    }),
    ...mapGetters(['getExecutionDetailParseArray']),
    getCurrentStep(){
      for (let index = 0; index < this.getExecutionDetailParseArray.length; index++) {
        if(this.getExecutionDetailParseArray[index].status === 'PENDING'){
          if((index + 1) <= (this.getExecutionDetailParseArray.length - 1)) {
            if(this.getExecutionDetailParseArray[index + 1].status === 'PENDING'){
              return index == 0 ? 0 : index - 1
            }
          } else {
            return index == 0 ? 0 : index - 1
          }
        }
      }
      return this.getExecutionDetailParseArray.length - 1
    },
    getStatusProgress(){
      if(this.getCurrentExecuteItem.status === 'ERROR' || this.getCurrentExecuteItem.status === 'OK') {
        return {
          value: 100,
          status: this.getCurrentExecuteItem.status
        }
      } else {
        let step = this.getCurrentStep === 0 && this.getExecutionDetailParseArray[this.getCurrentStep].status === 'PENDING' ? 0 : this.getCurrentStep + 1
        return {
          value: ((step) * 100) / this.getExecutionDetailParseArray.length,
          status: this.getExecutionDetailParseArray[this.getCurrentStep].status
        }
      }
    },
    getCurrentExecuteItem(){
      let currentExecuteItem = []
      if(this.executionsProgram.executions){
        currentExecuteItem = this.executionsProgram.executions.filter(item => item.id === this.$route.params.id)
      }
      return currentExecuteItem[0] || ''
    }
  },
  created(){
    this.selectedStepModel = this.getCurrentStep
  },
  methods: {
    getStepIcon(status){
      const icon = {
        "done": "check",
        "error": "warning",
        "executing": "mdi-circle-slice-5",
        "pending": ""
      }
      return icon[status.toLowerCase()]
    },
    getStepColor(status){
      const color = {
        "done": "#4CAF50",
        "ok": "#4CAF50",
        "error": "#EB6868",
        "executing": "#FB8C00",
        "pending": "#00000061"
      }
      return color[status.toLowerCase()]
    },
    getStepText(status){
      const text = {
        "done" : "Finalizado",
        "ok" : "Finalizado",
        "error" : "Alerta",
        "executing" : "Em progresso",
        "pending" : "Não iniciado"
      }
      return text[status.toLowerCase()]
    },
    getClassSteper(step, active){
      return `step-${step.status.toLowerCase()} ${active ? 'step-is-active' : ''}`
    }
  }
}
</script>

<style lang="scss">
.execution-detail {
  .v-progress-linear--rounded {
    border-radius: 15px;
  }
  .v-progress-linear__content {
    justify-content: space-between !important;
    padding: 0 10px;
  }
  .v-stepper {
    background: transparent;
  }
  .v-stepper__label {
    text-align: center;
    color: rgba(0, 0, 0, 0.6) !important;
    text-shadow: none!important;
  }
  .step-is-active, .step-is-active.error--text.step-error {
    .v-stepper__label {
      font-weight: bold!important;
      color: #000 !important;
      text-shadow: none!important;
    }
  }
  .error--text.step-error {
    color: #EB6868 !important;
    caret-color: #EB6868 !important;
    .v-stepper__label {
      color: #EB6868 !important;
    }
  }
  .error--text.step-executing {
    color: #FB8C00 !important;
    caret-color: #FB8C00 !important;
  }
  .v-stepper__header{
    flex-wrap: nowrap !important;
    .v-stepper__step {
      padding: 10px;
    }
  }
}
</style>

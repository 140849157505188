<template>
  <div class="posting-list">
    <BaseContainer
      :active-spacer="true"
      :permission-list="['admin']"
    >
      <template v-slot:header-title>
        {{ getTextMenu('postings', 'Lançamentos') }}
      </template>
      <template v-slot:header-title-description>
        Lista de lançamentos contábeis
      </template>

      <div class="posting-list__header__actions">
        <div class="mr-2">
          <BaseDatePicker
            name-placeholder="Data Inicial"
            :date-value="filter.start_date"
            :max="filter.end_date"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'start_date') }"
          />
        </div>
        <div class="mr-2">
          <BaseDatePicker
            name-placeholder="Data Final"
            :date-value="filter.end_date"
            :max="null"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'end_date') }"
          />
        </div>
        <div class="mr-2">
          <v-select
            v-model="filter.wallet"
            :items="getWallets()"
            label="Carteira"
          />
        </div>
        <div class="mr-2">
          <BaseInput
            v-model="filter.account"
            placeholder="Conta: e.g. assets"
          />
        </div>
        <BaseButton
          type="text"
          color="gray74"
          context-button="program"
          @click="filterPostings"
        >
          FILTRAR
        </BaseButton>
        <v-spacer />
        <div class="mr-2">
          <download-csv
            style="cursor: pointer; letter-spacing: unset;"
            title="Exportar CSV"
            class="v-btn v-size--default app-font-semibold"
            :data="dataPostingList"
            :fields="['occurred_at','posted_at','account','description','debit','credit']"
            :labels="{occurred_at: 'Data Ocorrência',posted_at: 'Data Lançamento',
                      account: 'Conta', description: 'Descrição', debit: 'Débito',
                      credit: 'Crédito'}"
            :name="getFileName()"
          >
            DOWNLOAD
            <v-icon>file_download</v-icon>
          </download-csv>
        </div>
      </div>

      <BaseDataTable
        :has-infinite-pagination="true"
        :headers="headers"
        background-color="white"
        :hide-headers="true"
        :hide-actions="true"
        :items="dataPostingList"
        item-key="id"
        classes="elevation-2 mt-6"
      >
        <template v-slot:inject-header="{headers}">
          <tr>
            <th
              v-for="(header, index) in headers.props.headers"
              :key="index"
              :hidden="header.permissions ? !userHasProgramRoles(header.permissions) : false"
              :class="[filter.descending ? 'desc' : 'asc', header.value === filter.sortBy ? 'active' : '', header.class]"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>

        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <td class="text-left">
              {{ props.item.occurred_at | getDate }}
            </td>
            <td class="text-left">
              {{ props.item.posted_at | getDate }}
            </td>
            <td class="text-left">
              {{ props.item.description }}
            </td>
            <td class="text-left">
              {{ props.item.debit }}
            </td>
            <td class="text-left">
              {{ props.item.credit }}
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import loader from '../../global/loader'
import BaseDataTable from '../BaseDataTable.vue'
import BaseDatePicker from '../BaseDatePicker.vue'
import ProgramRoles from "../../mixins/ProgramRoles";

export default {
  components: {BaseDataTable, BaseDatePicker},
  mixins: [ProgramRoles],
  data: () => ({
    filter: {
      start_date: '',
      wallet: '',
      account: '',
      end_date: ''
    },
    headers: [
      {text: 'Data Ocorrência', value: 'date', class: 'text-left date'},
      {text: 'Data Lançamento', value: 'date', class: 'text-left date'},
      {text: 'Descrição', value: 'description', class: 'text-left description'},
      {text: 'Débito', value: 'amount', class: 'text-left amount'},
      {text: 'Crédito', value: 'amount', class: 'text-left amount'},
    ]
  }),
  computed: {
    ...mapState({
      postingList: (state) => {
        return state.programMovements.programPostings
      },
      programInitiator: (state) => state.platformProgram.programInitiator
    }),
    dataPostingList() {
      return this.postingList.content ? this.postingList.content.map(post => {
        return {
          id: post.id,
          date: post.date,
          account: post.account.split(':').slice(4).join(":"),
          debit: post.amount < 0 ? '' : this.$options.filters.formatCurrency(post.amount),
          credit: post.amount >= 0 ? '' : this.$options.filters.formatCurrency(post.amount),
          description: post.description,
          occurred_at: post.occurred_at,
          posted_at: post.posted_at,
          event_id: post.event_id
        }
      }) : []
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramPostings']),
    ...mapActions(['apiGetProgramInfo']),
    ...mapActions(['apiGetWallets']),
    ...mapGetters(['getWallets']),
    ...mapMutations(["setDisableNavigation", "setFilter"]),
    convertStatus(value) {
      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok"
      }
      return status[value]
    },
    getPostings() {
      loader.startProgressLoadPage()
      this.apiGetProgramPostings(this.filter).then(() => {
        loader.stopProgressLoadPage()
      })
    },
    getFileName() {
      return "postings_" + new Date().getTime() + ".csv";
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    updateFilter(value, attribute) {
      this.filter[attribute] = value
    },
    filterPostings() {
      loader.startProgressLoadPage()
      this.apiGetProgramPostings(this.filter).then(() => {
        loader.stopProgressLoadPage()
      })
    }
  },
  created() {
    this.filter['start_date'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
    this.filter['end_date'] = this.filter['start_date']
  },
  async mounted() {
    if(!this.programInitiator){
      await this.apiGetProgramInfo()
    }
    this.getPostings()
    this.setFilter({initiator: this.programInitiator})
    loader.startProgressLoadPage()
    this.apiGetWallets().then((data) => {
      if (this.filter.wallet == '') {
        this.filter.wallet = data[data.length - 1].name
      }
      loader.stopProgressLoadPage()
    })
  }
}
</script>

<style lang="scss">
.posting-list__header__actions {
  display: flex;
  align-items: center;
}

.posting-list .base-data-table {
  .account {
    width: 40px;
  }

  .amount {
    width: 50px;
  }

  .date {
    width: 50px;
  }

  .description {
    width: 110px;
  }

}

.posting-list {
  .base-data-table th {
    font-weight: 600 !important;
  }

  .v-data-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>

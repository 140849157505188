<template>
  <div class="payment-list">
    <BaseContainer :active-spacer="true">
      <template v-slot:header-title>
        {{ getTextMenu('payments', headerTitle) }}
      </template>
      <template v-slot:header-title-description>
        {{ headerTitleDescription }}
      </template>

      <div class="payment-list__header__actions">
        <BaseFilters
          scope="payments"
          filter-scope="payment-table"
          :pagination="pagination"
          :load-page="loadPage"
          :is-admin-editing="isAdminEditing"
          @executeActionFilter="filterPayments"
        />
      </div>
      <BaseAlert :class="{ 'app-apply-blur' : isAdminEditing }">
        <b>Filtro aplicado:</b> {{ filterAlert }}
      </BaseAlert>
      <BaseDataTable
        :headers="tablePaymentHeaders"
        background-color="white"
        :hide-headers="true"
        :items="dataPaymentList"
        item-key="id"
        classes="elevation-2 mt-6"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        :total-items="paymentListTotalElements"
        :options="pagination"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-header="{headers}">
          <tr>
            <template
              v-for="(header, index) in headers.props.headers"
            >
              <th
                v-if="header.value != 'admin-config'"
                :key="index"
                style="white-space: nowrap;"
                :class="[filterData.pagination.sort_order, header.value === filterData.pagination.sort_by ? 'active' : '', header.class, isSortable(header) ? 'header-sortable' : '']"
                @click="isSortable(header) ? changeSort(header.value) : null"
              >
                {{ translateRoleType(header.text) }}
                <v-icon
                  v-if="isSortable(header)"
                  class="v-data-table-header__icon"
                  small
                >
                  arrow_upward
                </v-icon>
              </th>
            </template>
          </tr>
        </template>

        <template
          v-slot:inject-items="scopedItems"
        >
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <template
              v-for="header, index in tablePaymentHeaders"
            >
              <td
                v-if="header.value !== 'admin-config'"
                :key="index"
                class="text-left"
              >
                <div
                  v-if="header.hidden === 'true' && hideAllData"
                >
                  {{ hideData(props.item[header.value], header.hidden) }}
                </div>
                <div
                  v-else-if="header.value === 'status.name'"
                >
                  <v-chip
                    class="payment-list__item--status font-weight-medium"
                    :color="convertStatus(props.item[splitProp(header.value)[0]].category)"
                  >
                    {{ formatFields(header.value, props.item, header.input_type) || '-' }}
                  </v-chip>
                </div>

                <div v-else-if="header.value.includes('type.')">
                  <BaseTextTooltip
                    :text-item="formatFields(header.value, props.item, header.input_type) || '-'"
                    width-item="65"
                  />
                </div>

                <div v-else-if="header.value === 'to.name' || header.value === 'from.name'">
                  <BaseTextTooltip
                    :text-item="formatFields(header.value, props.item, header.input_type) || '-'"
                    width-item="100"
                    min-width-item="100%"
                  />
                </div>
                <div v-else>
                  {{ formatFields(header.value, props.item, header.input_type) || '-' }}
                </div>
              </td>
            </template>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import loader from '../global/loader'
import BaseDataTable from './BaseDataTable'
import ProgramRoles from '../mixins/ProgramRoles';
import BaseFilters from './BaseFilters'
import BaseAlert from './BaseAlert'
import BaseTextTooltip from './BaseTextTooltip'

export default {
  components: { BaseDataTable, BaseFilters, BaseAlert, BaseTextTooltip },
  mixins: [ ProgramRoles ],
  props:{
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: 'Pagamentos'
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: 'Acompanhe todas as suas movimentações.'
    }
  },
  data: () => ({
    filterData: {
      filters: [],
      category: 'payments',
      pagination: {}
    },
    pagination: {
      page: 1,
      itemsPerPage: 25
    },
    tablePaymentHeaders: [],
    fieldsPaymentColumn: [],
    filterAlert: 'Edite os filtros acima para mudar sua busca de pagamentos.',
    scopeSchema: 'payment-table',
  }),
  computed: {
    ...mapState({
      paymentList: (state) => {
        return state.programMovements.programMovements
      },
      loadPage: (state) => state.loadPage
    }),
    dataPaymentList() {
      return this.paymentList.content ? this.paymentList.content.map(element => {
        const {type, to, from, data} = element
        return {
          type,
          to,
          from,
          ...data
        }
      }) : []
    },
    paymentListTotalElements() {
      return this.paymentList.page ? this.paymentList.page.total_elements : 0
    }
  },
  async created(){
    let result = await this.apiGetSchemas({ scope: this.scopeSchema })
    this.fieldsPaymentColumn = result.data && result.data.fields || []
    this.parseHeadersPaymentTable()
  },
  methods: {
    ...mapActions('programMovements', ['apiGetSchemas']),
    ...mapActions('programFilters', ['apiPostMovementsFilters']),
    ...mapMutations('programMovements', ['setProgramMovements']),
    isSortable(header){
      return header.sortable
    },
    parseHeadersPaymentTable(){
      let tablePaymentColumn = this.fieldsPaymentColumn.sort((a, b) => a.sequence - b.sequence) || []
      let fields = tablePaymentColumn.map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key,
          'input_type': header['input_type'],
          sortable: true
        }
      })
      this.tablePaymentHeaders = [...fields]
    },
    convertStatus(value) {
      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok"
      }
      return status[value]
    },
    async changeSort(column) {
      if (this.filterData.pagination.sort_by === column) {
        this.filterData.pagination.sort_order = this.filterData.pagination.sort_order == 'desc'
          ? 'asc' : 'desc'
      } else {
        this.filterData.pagination.sort_by = column
        this.filterData.pagination.sort_order = 'desc'
      }

      this.filterData.pagination = {
        ...this.filterData.pagination,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage
      }

      let result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)
    },
    async getPaymentMovements() {
      let result = {}
      this.setPaginationToFilter()
      loader.startProgressLoadPage()

      result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)

      loader.stopProgressLoadPage()
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage
      }
      this.pagination = reducePaginationAttributes
      Object.assign(this.filterData.pagination, { page: pagination.page, size: pagination.itemsPerPage })
      this.getPaymentMovements()
    },
    setPaginationToFilter() {
      this.filterData.pagination = {
        ...this.filterData.pagination,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage
      }
    },
    filterPayments(filters) {
      this.pagination.page = 1
      Object.assign(this.filterData, filters)
      this.getPaymentMovements()
    }
  },
  beforeRouteLeave(to, from, next){
    this.setProgramMovements([])
    next()
  }
}
</script>
<style lang="scss">
.payment-list__header__actions {
  display: flex;
  align-items: center;
}
.payment-list {
  .base-data-table th {
    font-weight: 600 !important;
  }
  .v-data-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .payment-list__item--status {
    color: var(--custom-theme-black-054) !important;
    display: inline-block;
    font-size: 10px !important;
    height: 24px !important;
    min-width: 120px;
    text-align: center;
  }
}
</style>

<template>
  <BaseCard
    :has-divider="true"
    :has-main-container="true"
    base-card-title-class="app-font-semibold title mb-1"
  >
    <template v-slot:header-title>
      Tipos de Eventos
    </template>
    <template v-slot:header-actions>
      <v-dialog
        :value="isModalActive"
        persistent
        max-width="600"
        @input="createEventType"
      >
        <template v-slot:activator="{ on }">
          <BaseButton
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
            v-on="on"
          >
            Adicionar
          </BaseButton>
        </template>
        <BaseModal
          :show-indeterminate-progress="modalEventType.showIndeterminateProgress"
          :top-slot-content-title="modalEventType.topSlotContentTitle"
          :remove-button-close="modalEventType.removeButtonClose"
          :middle-slot-content-text-highlight="modalEventType.middleSlotContentTextHighlight"
          :middle-slot-content-text="modalEventType.middleSlotContentText"
          :middle-check-agree="modalEventType.middleCheckAgree"
          :bottom-buttons-align="modalEventType.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="modalEventType.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="modalEventType.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="modalEventType.removeButtonCancel"
          :remove-button-confirm="modalEventType.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="valid"
          @on-cancel="modalEventType.onCancel"
          @on-confirm="submitFormEventType"
        >
          <div style="margin: 0 auto;">
            <v-form
              ref="formEventType"
              v-model="valid"
            >
              <v-container>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventType.type"
                      :rules="[isValid]"
                      qa-identification-name="qa-id-name-eventType-type"
                      label="Tipo *"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventType.description"
                      :rules="[isValid]"
                      qa-identification-name="qa-id-name-eventType-description"
                      label="Descrição *"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </BaseModal>
      </v-dialog>
    </template>
    <BaseDataTable
      :items="eventTypesList"
      :headers="headers"
      :hide-actions="false"
      :has-infinite-pagination="true"
      :options="pagination"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="value in scopedItems"
          :key="value.index"
        >
          <td>
            {{ value.item.type }}
          </td>
          <td>
            {{ value.item.description }}
          </td>
          <td
            class="text-right"
          >
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              @click="modalDeleteEventType(value.item.type)"
            >
              Apagar
            </BaseButton>
          </td>
        </tr>
        <v-dialog
          v-model="deleteEventTypeModalActive"
          max-width="560"
          persistent
        >
          <BaseModal
            :show-indeterminate-progress="deleteEventTypeModal.showIndeterminateProgress"
            :top-slot-content-title="deleteEventTypeModal.topSlotContentTitle"
            :remove-button-close="deleteEventTypeModal.removeButtonClose"
            :middle-slot-content-text-highlight="deleteEventTypeModal.middleSlotContentTextHighlight"
            :middle-slot-content-text="deleteEventTypeModal.middleSlotContentText"
            :bottom-buttons-align="deleteEventTypeModal.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="deleteEventTypeModal.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="deleteEventTypeModal.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="deleteEventTypeModal.removeButtonCancel"
            :remove-button-confirm="deleteEventTypeModal.removeButtonConfirm"
            @on-cancel="() => deleteEventTypeModalActive=false"
            @on-confirm="() => deleteEventTypeModal.onConfirm()"
          />
        </v-dialog>
      </template>
    </BaseDataTable>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </BaseCard>
</template>
<script>
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'

export default {
  components: { BaseDataTable, BaseModal, BaseSnackbar },
  mixins: [ BaseSnackbarConfiguration ],
  data() {
    return {
      valid: false,
      isModalActive: false,
      deleteEventTypeModalActive: false,
      deleteEventTypeModal: {},
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy:['type']
      },
      headers: [
        { text: "Tipo", value: "type" },
        { text: "Descrição", value: "description" },
        { text: "", value: "actions", sortable: false}
      ],
      eventType: {
        type: "",
        description: ""
      },
      modalEventType: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Tipo de Evento",
        removeButtonClose: true,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      modalDeleteEventType(type){
        Object.assign(this.deleteEventTypeModal, {
          showIndeterminateProgress: false,
          topSlotContentTitle: "Excluir Tipo de Evento",
          removeButtonClose: false,
          middleSlotContentTextHighlight: "",
          middleSlotContentText: `Tem certeza que deseja excluir esse Tipo de Evento?`,
          bottomButtonsAlign: "right",
          bottomSlotContentButtonCancelLabel: "Fechar",
          bottomSlotContentButtonConfirmLabel: "Excluir",
          removeButtonCancel: false,
          removeButtonConfirm: false,
          onConfirm: () => this.deleteEventType(type)
        })
        this.deleteEventTypeModalActive = true
      },
    }
  },

  computed: {
    ...mapState({
      eventTypesList: state => state.common.eventTypesList
    })
  },

  async created(){
    await this.apiGetEventTypes()
  },

  methods: {
    ...mapActions(['apiGetEventTypes', 'apiCreateEventType', 'apiDeleteEventType']),

    createEventType() {
      this.isModalActive = true

      this.eventType = {
        type: '',
        description: '',
      }
    },

    async submitFormEventType(){
      try {
        const payload = {
          type: this.eventType.type,
          description: this.eventType.description
        }
        
        await this.apiCreateEventType(payload)

        this.showFeedback({
          message: 'Tipo de evento criado com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar tipo de evento!',
        })
      } finally {
        this.isModalActive = false
        await this.apiGetEventTypes()
      }
    },

    async deleteEventType(value){
      try {
        await this.apiDeleteEventType({ type: value })
        this.showFeedback({
          message: 'Tipo de evento removido com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao remover tipo de evento!',
        })
      } finally {
        await this.apiGetEventTypes()
        this.deleteEventTypeModalActive = false
      }
    },

    closeModal(){
      this.isModalActive = false
    }

  }
}
</script>
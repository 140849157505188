<template>
  <div
    :class="isAdminEditing ? 'box-config-movement' : ''"
  >
    <div v-if="isAdminEditing">
      <div class="app-apply-blur">
        <h1 class="mb-6 text-h5 primary--text font-weight-bold pb-0">
          Criar proposta
        </h1>
        <h4>NEGOCIAÇÃO</h4>
        <p class="mb-4 mt-2">
          Número &nbsp; VALOR NÚMERO <br />
          Fornecedor &nbsp; VALOR FORNECEDOR
        </p>
        <v-divider />
        <span class="primary--text">
          <p class="mb-2 mt-4">Quantidade</p>
          <p class="mb-6 mt-2">Valor total</p>
        </span>
        <h4 class="mb-2">
          DADOS DE PAGAMENTO
        </h4>
        <v-divider />
        <p class="mb-8 mt-2 d-flex justify-end">
          Código Banco
          &nbsp; &nbsp;
          Agência
          &nbsp; &nbsp;
          Conta
        </p>
      </div>

      <tr class="d-flex align-center justify-space-between">
        <td>
          <h4>PROPOSTA</h4>
          <p class="mb-2">
            Informe a taxa e o valor da proposta
          </p>
        </td>
        <td style="margin-top: -10px;">
          <MenuConfigurationSchemaField
            v-if="isAdminEditing"
            menu-title="Configurar"
            name="configurationMovementDetail"
            menu-align="left"
            :attach-menu="false"
            :config-list="programMovementDynamicAssetFields"
            :form-field-list="['name', 'input_type', 'type', 'required', 'read_only', 'datasource', 'help_text', 'validation', 'hidden']"
            :scope-schema="scopeSchema"
            @removeItemSchema="removeItemSchema"
            @addOrEditItemSchema="addOrEditItemSchema"
            @changeOrderItemSchema="changeOrderItemSchema"
          />
        </td>
      </tr>
      <v-divider />
    </div>
    <v-form
      :ref="offer.ref"
      :value="offer.valid"
      :disabled="offer.hasSendingOffer"
      @input="handleInputForm"
    >
      <div class="form-grid">
        <div
          v-for="field in programMovementDynamicAssetFields"
          :key="field.id"
          class="mx-1 mt-2"
        >
          <template v-if="field.input_type == 'amount'">
            <BaseInput
              :ref="field.key"
              v-model="offer[field.key]"
              v-money="amountMask"
              :rules="validateAmountField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :label="getLabelFieldInput(field)"
              :qa-identification-name="`qa-id-name-${field.key}`"
            />
          </template>
          <template v-if="field.input_type == 'percent'">
            <BaseInput
              :ref="field.key"
              v-model="offer[field.key]"
              v-money="rateMask"
              :rules="validateTaxField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :label="getLabelFieldInput(field)"
              :qa-identification-name="`qa-id-name-${field.key}`"
            />
          </template>
          <template v-if="field.input_type == 'text'">
            <CnpjInput
              v-if="field.key.includes('tax-number')"
              :ref="field.key"
              v-model="offer[field.key]"
              :rules="validateField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :label="getLabelFieldInput(field)"
              :qa-identification-name="`qa-id-name-${field.key}`"
            />
            <BaseInput
              v-else
              :ref="field.key"
              v-model="offer[field.key]"
              :rules="validateField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :label="getLabelFieldInput(field)"
              :qa-identification-name="`qa-id-name-${field.key}`"
            />
          </template>
          <template v-if="field.input_type == 'date'">
            <BaseDatePicker
              :ref="field.key"
              :name-placeholder="getLabelFieldInput(field)"
              :date-value="offer[field.key]"
              :max="null"
              locale="pt-br"
              time-zone="UTC"
              :rules="validateField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :qa-identification-name="`qa-id-name-${field.key}`"
              @on-input-date-picker="(value) => { updateFilter('offer', value, field.key) }"
            />
          </template>
          <template v-if="field.input_type == 'select' || field.input_type == 'multiselect'">
            <v-select
              :ref="field.key"
              v-model="offer[field.key]"
              :items="getOptionsList(field)"
              item-text="name"
              item-value="value"
              :label="getLabelFieldInput(field)"
              :rules="validateField(field.required)"
              :disabled="disableField(field.read_only, field.validation)"
              :qa-identification-name="`qa-id-name-${field.key}`"
            />
          </template>
        </div>
      </div>
    </v-form>
    <div class="d-flex justify-end">
      <BaseButton
        v-if="offer.status && offer.status.category ==='on-hold'"
        type="text"
        color="primary"
        :is-disabled="!inputFormValid"
        @click="updateOffer()"
      >
        Recalcular
      </BaseButton>
    </div>

    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { rateMask, amountMask } from "../directives/moneyMasks";
import { mapActions, mapState } from 'vuex'
import MenuConfigurationSchemaField from "./Platform/MenuConfigurationSchemaField";
import BaseSnackbar from '../components/BaseSnackbar'
import BaseSnackbarConfiguration from '../mixins/BaseSnackbarConfiguration'
import BaseDatePicker from './BaseDatePicker'
import CnpjInput from "../components/Input/CnpjInput"

export default {
  name: "FormCreateOffer",
  components: { MenuConfigurationSchemaField, BaseSnackbar, BaseDatePicker, CnpjInput },
  mixins: [BaseSnackbarConfiguration],
  props: {
    clearForm: {
      type: Boolean,
      required: false
    },
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    scopeSchema: {
      type: String,
      required: true
    },
    offerObject: {
      type: Object,
      required: false,
      default: () => {}
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    offer: {},
    rateMask,
    amountMask,
    inputFormValid: false
  }),

  computed: {
    ...mapState({
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields,
    }),
  },
  watch: {
    offer: {
      handler() {
        this.$emit("changeOfferObject", this.offer);
      },
      deep: true,
    },
    active(currentValue) {
      if (currentValue) {
        this.updateOfferFormFields()
      } else {
        this.clearOfferForm()
      }
    }
  },
  created(){
    if (this.active) {
      this.updateOfferFormFields()
    } else {
      this.clearOfferForm()
    }
  },
  methods: {
    ...mapActions(['apiGetSchemasFields', 'apiUpdateSchemaField', 'apiCreateSchemaField']),
    ...mapActions('programMovements', ['apiGetSchemas', 'apiPatchProgramMovements']),
    getLabelFieldInput(field){
      return field.required == "true" ? `${field.name} *` : field.name
    },
    async updateOffer(){

      let newOffer = {}
      const { currentOfferForApproveOrReprove, currentTradeOffer, from, to, ref, valid, status, hasSendSuccess, hasSendingOffer, ...data } = this.offer

      this.programMovementDynamicAssetFields.forEach((field) => {
        let key = field.key
          if(field.input_type === 'percent'){
            newOffer = {
              ...newOffer,
              [key]: this.convertToDecimal(this.parseNumberDecimal(data[key])).toFixed(6)
            }
          } else if (field.input_type === 'amount') {
            newOffer = {
              ...newOffer,
              [key]: this.parseNumberDecimal(data[key]).toFixed(2)
            }
          } else {
            newOffer = {
              ...newOffer,
              [key]: data[key] == undefined ? '' : data[key]
            }
          }
        })

      const payload = {
        action: "update",
        data: {
            id: this.offer.id,
            movement_action: "update",
            category: "offer",
            "trade_amount": currentTradeOffer.amount,
            ...newOffer,
            parent: {
              category: "trade",
              id: currentTradeOffer.id,
              date: currentTradeOffer.date,
              "external-id": currentTradeOffer['external_id']
            }
        }
      }
      try {
        const result = await this.apiPatchProgramMovements({ category: 'offers?sync=true', data: payload })

        this.$nextTick(() => {
          this.offer = Object.assign({}, {
            currentOfferForApproveOrReprove,
            currentTradeOffer,
            hasSendingOffer,
            hasSendSuccess,
            status,
            valid,
            from,
            ref,
            to,
            date: this.offer.date,
            ...result.data.data
          })
        })
        this.forceUpdateValues(result.data.data)

      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao recalcular.',
        })
      }
    },
    handleInputForm(disabled) {
      this.inputFormValid = disabled
      this.$emit("changeHandleInputForm", disabled);
    },
    updateOfferFormFields() {
      Object.assign(this.offer, {
        ...this.offerObject,
        ...this.offerObject.currentOfferForApproveOrReprove
      })
      this.forceUpdateValues(this.offer)
    },
    forceUpdateValues(offer){
      this.programMovementDynamicAssetFields.forEach(field => {
        if(this.$refs[field.key] &&
        (field.input_type === 'amount' ||
        field.input_type === 'percent')){

          if(field.input_type === 'percent'){
            this.$refs[field.key][0].$el.getElementsByTagName('input')[0].value = this.parseTax(offer[field.key])
          } else {
            let splitObject = offer[field.key].split('.')
            if(splitObject[splitObject.length-1].length == 1){
              offer[field.key] += '0'
            }
            this.$refs[field.key][0].$el.getElementsByTagName('input')[0].value = offer[field.key]
          }
        }
      });
    },
    parseTax(value){
      return this.convertToPercentage(value).toFixed(4)
    },
    clearOfferForm() {
      Object.keys(this.$refs).forEach((elem) => {
        this.forceResetInputValue(this, elem);
      })

      this.offer = Object.assign({}, {})

      if(this.$refs[this.offer.ref]){
        this.$refs[this.offer.ref].reset();
        this.cancelForm(this, this.offer.ref)
      }
      this.$emit("changeClearOfferForm", false);
    },
    validateField(value){
      if(value == "true")
      return [this.isValid]
    },
    getOptionsList(field){
      if(field.datasource.startsWith('[')){
        return JSON.parse(field.datasource)
      }
    },
    validateAmountField(value){
      if(value == "true")
      return [this.isAmountValid]
    },
    validateTaxField(value){
      if(value == "true")
      return [this.isValidLengthForTax]
    },
    disableField(readOnly, validation) {
      return readOnly == 'true' ?
      true :
      validation === 'disabled' ? true : false
    },
    async createSchema(payload) {
      try {
        await this.apiCreateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
      }
    },
    async updateSchema(payload){
      try {
        await this.apiUpdateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao atualizar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
      }
    },
    addOrEditItemSchema({key, role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: [],
        role,
        program: programName
      }

      if(this.programMovementDynamicAssetFields.length) {
        const payloadList = this.programMovementDynamicAssetFields.filter(field => field.key !== key)
        payloadList.push(data)

        Object.assign(payload, {
          ...payload,
          fields: payloadList
        })
        this.updateSchema(payload)
      } else {
        Object.assign(payload, {
          ...payload,
          fields: [data]
        })
        this.createSchema(payload)
      }
    },
    changeOrderItemSchema({ role, schemaName, programName, data }){
        const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: data,
        role,
        program: programName
      }
      this.updateSchema(payload)
    },
    async removeItemSchema({ field, role, schemaName, programName }) {
      const payloadList = this.programMovementDynamicAssetFields.filter(elem => elem.key !== field.key)
      try {
        await this.apiUpdateSchemaField({
          name: schemaName,
          scope: this.scopeSchema,
          fields: payloadList,
          role: role,
          program: programName
        })
        this.showFeedback({
          message: 'Campo removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao remover campo.',
          color: 'error'
        })
      } finally {
        await this.apiGetSchemasFields()
        await this.apiGetSchemas({ scope: this.scopeSchema, role })
      }
    },
  },
};
</script>

<style lang="scss">
  .box-config-movement{
    margin: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 1px 5px #00000061;
  }
  .form-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
</style>

<template>
  <div class="developer-incoming-files">
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="0"
    >
      <template v-slot:header-title>
        Arquivos Recebidos
      </template>
      <v-row class="mt-4">
        <v-col sm="3">
          <v-select
            v-model="filter.initiator"
            :items="getInitiators"
            clearable
            label="Originador"
          />
        </v-col>
        <v-col sm="3">
          <BaseInput
            v-model="filter.context"
            label="Contexto"
            clearable
            class="mr-2"
          />
        </v-col>
        <v-col sm="2">
          <BaseDatePicker
            name-placeholder="Data Inicial"
            :date-value="filter.startDate"
            :max="null"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateDatePicker(value, 'startDate') }"
          />
        </v-col>
        <v-col sm="2">
          <BaseDatePicker
            name-placeholder="Data Final"
            :date-value="filter.endDate"
            :max="null"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateDatePicker(value, 'endDate') }"
          />
        </v-col>
        <v-col
          sm="2"
          class="app-align-items-center"
        >
          <BaseButton
            type="text"
            color="primary"
            @click="filterEnvelopeList"
          >
            Filtrar
          </BaseButton>
        </v-col>
      </v-row>
      <BaseDataTable
        :headers="headers"
        :items="incomingFiles"
        :hide-actions="false"
        background-color="transparent"
        :total-items="((pagination.page + 1) * pagination.itemsPerPage) + 1"
        :has-infinite-pagination="true"
        :options="pagination"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td>
              {{ value.item.createdAt | getDate }} - {{ value.item.createdAt | getFullTime }}
            </td>
            <td>
              {{ value.item.initiator }}
            </td>
            <td>
              {{ value.item.context }}
            </td>
            <td>
              {{ value.item.fileName }}
            </td>
            <td>
              {{ value.item.filePath }}
            </td>
            <td>
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="downloadFile(value.item.uuid, value.item.fileName)"
              >
                Baixar
              </BaseButton>
            </td>
          </tr>
        </template>
        <template v-slot:custom-text-no-data>
          <div class="text-center">
            Nenhum arquivo para exibir
          </div>
        </template>
      </BaseDataTable>
    </BaseCard>
  </div>
</template>

<script>
import BaseDataTable from '../../BaseDataTable'
import { mapActions, mapGetters, mapState } from 'vuex'
import permissions from '../../../mixins/permissions';
import BaseDatePicker from '../../BaseDatePicker'


export default {
  name: 'DeveloperIncomingFiles',
  components: { BaseDataTable, BaseDatePicker },
  mixins: [ permissions ],
  data() {
    return {
      filter: {
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        initiator: '',
        context: '',
      },
      pagination: {
        page: 1,
        itemsPerPage: 25
      },
      headers: [
        {
          text: 'Data',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Originador',
          value: 'initiator',
          sortable: false
        },
        {
          text: 'Contexto',
          value: 'context',
          sortable: false
        },
        {
          text: 'Nome',
          value: 'fileName',
          sortable: false
        },
        {
          text: 'Path',
          value: 'filePath',
          sortable: false
        },
        {
          text: '',
          value: 'uuid',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getInitiators']),
    ...mapState({
      incomingFiles: state => state.incomingFiles.incomingFiles
    })
  },
  async created(){
    await this.apiGetInitiators();
    await this.getIncomingFiles();
  },
  methods: {
    ...mapActions(['apiGetInitiators', 'apiGetDownloadFile', 'apiGetIncomingFiles']),
    updateDatePicker(value, prop) {
      this.$set(this.filter, prop, value)
    },
    downloadFile(uuid, name) {
      this.apiGetDownloadFile({
        uuid: uuid,
        fileName: name
      })
    },
    async getIncomingFiles() {
      let payload  = {
        ...this.filter,
        context: this.filter.context ? `%${this.filter.context.trim()}%` : '',
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page - 1
      }
      await this.apiGetIncomingFiles(payload);
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
      }
      this.pagination = reducePaginationAttributes
      this.getIncomingFiles()
    },
    filterEnvelopeList(){
      this.pagination.page = 1
      this.getIncomingFiles()
    }
  }
}
</script>


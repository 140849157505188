<template>
  <div class="developer-initiators">
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="0"
    >
      <template v-slot:header-title>
        Originadores
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalAddOrEditActive"
          persistent
          max-width="600"
          @input="changeModalContext('create')"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              v-on="on"
            >
              Adicionar
            </BaseButton>
          </template>
          <BaseModal
            :show-indeterminate-progress="modalAddOrEdit.showIndeterminateProgress"
            :top-slot-content-title="modalAddOrEdit.topSlotContentTitle"
            :remove-button-close="modalAddOrEdit.removeButtonClose"
            :middle-slot-content-text-highlight="modalAddOrEdit.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalAddOrEdit.middleSlotContentText"
            :middle-check-agree="modalAddOrEdit.middleCheckAgree"
            :bottom-buttons-align="modalAddOrEdit.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalAddOrEdit.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalAddOrEdit.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalAddOrEdit.removeButtonCancel"
            :remove-button-confirm="modalAddOrEdit.removeButtonConfirm"
            :custom-enable-bottom-button-confirm-label="(valid && modalAddOrEdit.middleCheckAgree == null) || (modalAddOrEdit.middleCheckAgree !== null && modalAddOrEdit.middleCheckAgree.isChecked)"
            @on-cancel="modalAddOrEdit.onCancel"
            @on-confirm="modalAddOrEdit.onConfirm"
            @input="(value) => checkAgree(value)"
          >
            <template
              v-if="modalAddOrEdit.middleCheckAgree == null"
              v-slot:default
            >
              <div style="margin: 0 auto">
                <v-form
                  ref="formAddOrEdit"
                  v-model="valid"
                >
                  <v-container>
                    <v-row dense>
                      <v-col
                        cols="auto"
                        sm="12"
                      >
                        <BaseInput
                          v-model="initiator.name"
                          :rules="[isValid]"
                          qa-identification-name="qa-id-name-initiator-name"
                          label="Nome *"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="auto"
                        sm="12"
                      >
                        <BaseInput
                          v-model="initiator.code"
                          :rules="[isValid]"
                          qa-identification-name="qa-id-name-initiator-code"
                          label="Código *"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-combobox
                          v-model="initiator.emails"
                          :items="initiator.emails"
                          label="Email(s) *"
                          hint="Ao digitar cada email aperte 'enter' para confirmar."
                          persistent-hint
                          multiple
                          chips
                          :rules="[isValidArray]"
                          name="qa-id-name-initiator-email"
                          :error-messages="errorMessage"
                          class="step-name-configuration_combobox"
                          @change="validateEmailList(initiator.emails)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        sm="2"
                        class="pb-0"
                      >
                        <v-checkbox
                          v-model="hasAlias"
                          primary
                          label="Alias"
                          hint="Basear-se em um originador já existente."
                          class="mt-6 mb-0"
                        />
                      </v-col>
                      <v-col
                        v-if="hasAlias"
                        class="pb-0"
                      >
                        <v-select
                          v-model="initiator.alias"
                          :items="getInitiators"
                          label="Originador *"
                          qa-identification-name="qa-id-name-initiator-initiator"
                          :rules="[isValid]"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </template>
          </BaseModal>
        </v-dialog>
      </template>
      <BaseDataTable
        :headers="headers"
        :items="initiators"
        :hide-actions="false"
        background-color="transparent"
        :options="pagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td>
              {{ value.item.code }}
            </td>
            <td>
              {{ value.item.emails }}
            </td>
            <td class="color--text-black-038">
              {{ value.item.alias }}
            </td>
            <td class="text-right">
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="changeModalContext('edit', value.item)"
              >
                Editar
              </BaseButton>
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="changeModalContext('delete', value.item)"
              >
                Apagar
              </BaseButton>
            </td>
          </tr>
        </template>
        <template v-slot:custom-text-no-data>
          <div class="text-center">
            Nenhum arquivo para exibir
          </div>
        </template>
      </BaseDataTable>
    </BaseCard>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'

export default {
  name: 'DeveloperInitiators',
  components: { BaseDataTable, BaseModal, BaseSnackbar},
  mixins: [BaseSnackbarConfiguration],
  data() {
    return {
      valid: false,
      isModalAddOrEditActive: false,
      modalAddOrEdit: {},
      initiator: {},
      hasAlias: false,
      pagination: {
        page: 1,
        itemsPerPage: 10
      },
      headers: [
        {
          text: 'Nome',
          value: 'name',
          sortable: false
        },
        {
          text: 'Emails',
          value: 'emails',
          sortable: false
        },
        {
          text: 'Alias',
          value: 'alias',
          sortable: false
        },
        {
          text: '',
          value: 'uuid',
          sortable: false,
          align: 'end'
        }
      ],
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters(['getInitiators']),
    ...mapState({
      initiators: state => state.common.initiators.sort((a, b) => a.code.localeCompare(b.code))
    })
  },
  async created(){
    this.setDefaultModal()
    await this.apiGetInitiators()
  },
  methods: {
    ...mapActions(['apiGetInitiators', 'apiUpdateInitiator', 'apiCreateInitiator', 'apiDeleteInitiator']),
    closeModalAddOrEdit(){
      this.isModalAddOrEditActive = false
      if(this.$refs.formAddOrEdit){
        this.$refs.formAddOrEdit.reset()
      }
      this.initiator = {
        emails: []
      }
      this.checkAgree(false)
      this.setDefaultModal()
    },
    setDefaultModal(){
      Object.assign(this.modalAddOrEdit, {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Adicionar Originador",
        removeButtonClose: true,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        middleCheckAgree: null,
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModalAddOrEdit,
        onConfirm: () => this.crudInitiator('create'),
      })
    },
    checkAgree(value) {
      if(this.modalAddOrEdit.middleCheckAgree){
        this.$set(this.modalAddOrEdit.middleCheckAgree, 'isChecked', value)
        this.$forceUpdate()
      }
    },
    validateEmailList(emailList){
      this.errorMessage = this.isEmailValid(emailList)
    },
    changeModalContext(context, item = {}){
      Object.assign(this.initiator, item)
      this.hasAlias = item.alias ? true : false

      if(context == 'create'){
        Object.assign(this.modalAddOrEdit,
          {
            ...this.modalAddOrEdit,
            topSlotContentTitle: "Adicionar Originador",
            bottomSlotContentButtonCancelLabel: "Cancelar",
            bottomSlotContentButtonConfirmLabel: "Adicionar",
            middleCheckAgree: null,
            onConfirm: () => this.crudInitiator('create')
          }
        )
      } else if(context == 'edit') {
        this.initiator.emails = item.emails ? item.emails.split(',') : []
        Object.assign(this.modalAddOrEdit,
          {
            ...this.modalAddOrEdit,
            topSlotContentTitle: "Editar Originador",
            bottomSlotContentButtonCancelLabel: "Cancelar",
            bottomSlotContentButtonConfirmLabel: "Salvar",
            middleCheckAgree: null,
            onConfirm: () => this.crudInitiator('update')
          }
        )
      } else if(context == 'delete') {
        this.initiator.emails = [item.emails]
        Object.assign(this.modalAddOrEdit,
          {
            ...this.modalAddOrEdit,
            topSlotContentTitle: "Excluir Originador",
            bottomSlotContentButtonCancelLabel: "Cancelar",
            bottomSlotContentButtonConfirmLabel: "Excluir",
            middleCheckAgree: {
              "text": "Estou ciente de que após clicar no botão excluir, a ação não poderá ser desfeita.",
              "isChecked": false,
            },
            middleSlotContentText: `<p class='my-6'>Tem certeza que deseja <b>excluir o originador ${this.initiator.name}</b>?</p>`,
            onConfirm: () => this.crudInitiator('delete')
          }
        )
      }
      this.isModalAddOrEditActive = true
    },
    async crudInitiator(action){
      const responseMsg = {
        'create': ['criado', 'criar'],
        'delete': ['excluído', 'excluir'],
        'update': ['editado', 'editar']
      }
      const payload = {
        ...this.initiator,
        emails: this.initiator.emails.join()
      }
      try {
        switch(action){
          case 'update':
            await this.apiUpdateInitiator(payload)
            break
          case 'create':
            await this.apiCreateInitiator(payload)
            break
          case 'delete':
            await this.apiDeleteInitiator(this.initiator.uuid)
            break
        }
        this.closeModalAddOrEdit()
        await this.apiGetInitiators()
        this.showFeedback({
          color: "success",
          message: `Originador ${responseMsg[action][0]} com sucesso!`,
        })
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: `Erro ao ${responseMsg[action][1]} originador!`,
        })
      }
    }
  }
}
</script>


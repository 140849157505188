<template>
  <div class="step-program-documents">
    <div class="text-center">
      <h1 class="font-weight-bold title mb-1 mt-6 color--text-black-054">
        Documentos do programa
      </h1>
      <p class="mb-6 color--text-black-054 body-2">
        Inclua os documentos do programa que ficarão disponíveis para os participantes.
      </p>
    </div>
    <BaseDataTable
      v-if="dataProgramDocuments.length > 0"
      :items="dataProgramDocuments"
      :hide-actions="true"
      :hide-headers="true"
      :headers="filteredHeaders"
      background-color="transparent"
    >
      <template v-slot:inject-header="{headers}">
        <tr>
          <th
            v-for="(header, index) in headers.props.headers"
            :key="index"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>

      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="props in scopedItems"
          :key="props.index"
        >
          <td>
            <div
              v-if="!props.item.editingName"
              class="app-align-items-center"
            >
              {{ props.item.name }}
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="props.item.editingName = true"
              >
                Editar
              </BaseButton>
            </div>
            <div
              v-if="props.item.editingName"
              style="width:250px"
            >
              <BaseInput
                v-model="props.item.name"
                label="Nome do documento*"
                :rules="[isValid]"
                append-icon="check_circle"
                @click:append="updateInputName(props.index)"
              />
            </div>
          </td>
          <td>
            <v-row
              v-if="hasFile(props.item.url)"
              justify="center"
            >
              <v-checkbox
                :input-value="props.item.buyer"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'buyer')"
              />
            </v-row>
          </td>
          <td>
            <v-row
              v-if="hasFile(props.item.url)"
              justify="center"
            >
              <v-checkbox
                :input-value="props.item.trading_partner"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'trading_partner')"
              />
            </v-row>
          </td>
          <td>
            <v-row
              v-if="hasFile(props.item.url)"
              justify="center"
            >
              <v-checkbox
                :input-value="props.item.seller"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'seller')"
              />
            </v-row>
          </td>
          <td>
            <div v-if="!hasFile(props.item.url)">
              <v-file-input
                v-show="false"
                :ref="props.item.id"
                v-model="props.item.file"
                @change="uploadDocument(props.item, props.index)"
              />
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 mx-0"
                @click="selectDocument(props.item.id)"
              >
                Upload do arquivo
                <v-progress-circular
                  v-if="props.item.isUploadingDocument"
                  indeterminate
                  class="app-button--is-loading mr-0 ml-2"
                />
              </BaseButton>
            </div>
            <div
              v-else
              class="app-align-items-center action-table-content"
              style="width: 176px;"
            >
              <p class="truncate-content-text">
                {{ props.item.url }}
              </p>
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 button-action-table"
                @click="removeFileUrl(props.index)"
              >
                Excluir
              </BaseButton>
            </div>
          </td>
          <td>
            <BaseButton
              type="text"
              :has-icon="true"
              color="primary"
              class="ma-0"
              @click="deleteDocument(props.item.id, props.index)"
            >
              <v-icon>delete</v-icon>
            </BaseButton>
          </td>
        </tr>
      </template>
    </BaseDataTable>

    <BaseButton
      type="text"
      color="blueff"
      class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
      :class="{'d-flex button-center': dataProgramDocuments.length === 0}"
      :is-disabled="hasEmptyLine"
      @click="addNewDocumentLine"
    >
      Adicionar Documento
    </BaseButton>

    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState} from 'vuex'
import BaseActionFooter from '../../BaseActionFooter'
import BaseDataTable from '../../BaseDataTable'

export default {
  components: {BaseActionFooter, BaseDataTable },
  data() {
    return {
      headers: [
        {
          text: 'Documento',
          value: 'name',
          class: 'name',
        },
        {
          text: 'Âncora',
          value: 'buyer',
          class: 'buyer text-center',
        },
        {
          text: 'Comprador',
          value: 'trading-partner',
          class: 'trading-partner text-center',
        },
        {
          text: 'Vendedor',
          value: 'seller',
          class: 'seller text-center'
        },
        {
          text: 'Arquivo',
          value: 'file',
          class: 'file'
        },
        {
          text: '',
          value: 'delete',
          class: 'delete'
        }
      ],
      backButton: {
        action: ()=> { this.$emit("onPreviousStep", 4) },
        text: 'Voltar',
        disabled: false
      },
      cancelButton: {
        action: ()=> { this.$router.push('/platform-home') },
        text: 'Cancelar',
        disabled: false
      },
      confirmButton: {
        action: this.nextStep,
        text: 'Avançar',
        disabled: true
      }
    }
  },
  computed:{
    ...mapState({
      programId: (state) => {
        return state.platformProgram.programId
      },
      programDocuments: (state) => {
        return state.platformProgram.programDocuments
      },
      programSelectedRoles: (state) => {
        return state.platformProgram.programSelectedRoles
      },
      programInitiator: (state) => {
        return state.platformProgram.programInitiator
      }
    }),
    dataProgramDocuments() {
      return this.programDocuments.map(element => {
        const { id, name, roles, file_uri } = element
        return {
          id: id,
          name: name,
          editingName: name ? false : true,
          buyer: this.searchInList(roles, "buyer"),
          trading_partner: this.searchInList(roles, "trading-partner"),
          seller: this.searchInList(roles, "seller"),
          url: file_uri,
          file: null,
          isUploadingDocument: false
        }
      })
    },
    hasEmptyLine(){
      return this.dataProgramDocuments.find(e => e.name == "" || e.url == "-" || (e.buyer == false && e.trading_partner == false && e.seller == false)) ? true : false
    },
    filteredHeaders() {
      return this.headers.map(elem => {
        this.programSelectedRoles.find(e => {
          if(e.type === elem.value){
            elem.text = e.description
          }
        })
        return elem
      })
    }
  },
  watch: {
    dataProgramDocuments: {
      handler() {
        this.confirmButton.disabled = this.hasEmptyLine ? true : false
      },
      deep: true
    }
  },
  async created() {
    if(this.programId){
      await this.apiGetProgramDocuments(this.programId)
      await this.apiGetProgramSelectedRoles(this.programId)
    }
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setUseActionFooter"]),
    ...mapActions(['apiGetProgramDocuments', 'apiUploadProgramDocument', 'apiCreateProgramDocuments','apiUpdateProgramDocuments', 'apiDeleteProgramDocuments', 'apiGetProgramSelectedRoles']),
    addNewDocumentLine(){
      const newLine = {
        "id": "",
        "name": "",
        "roles": "",
        "file_uri": "-"
      }
      this.programDocuments.push(newLine)
    },
    nextStep(){
      this.$emit("onNextStep", 6)
    },
    searchInList(list, elem){
      return list.indexOf(elem) > -1 ? true : false
    },
    changeCheckbox(index, field){
      this.dataProgramDocuments[index][field]= !this.dataProgramDocuments[index][field]
      this.createOrUpdateDocument(this.dataProgramDocuments[index])
    },
    dataProgramDocumentsForAPI(item) {
      const { id, name, buyer, trading_partner, seller, url } = item
      return {
        programId: this.programId,
        id: id,
        name: name,
        roles: this.roleString({buyer: buyer, "trading-partner": trading_partner, seller: seller}),
        file_uri: url
      }
    },
    roleString(ob){
      let string = ''
      for(let key in ob){
        if(ob[key]){
          string += `${key},`
        }
      }
      return string.substring(0, string.length - 1)
    },
    updateInputName(index){
      this.dataProgramDocuments[index].editingName = false
      this.createOrUpdateDocument(this.dataProgramDocuments[index])
    },
    selectDocument(id) {
      this.$refs[id][0].$el.querySelector('input').click()
    },
    hasFile(url) {
      return url === '-' ? false : true
    },
    async uploadDocument(item, index){
      this.dataProgramDocuments[index].isUploadingDocument = true
      let formData = new FormData();

      if(item.file){
        formData.append('file', item.file);
        formData.append('content-type', item.file.type);
        try {
          await this.apiUploadProgramDocument({programInitiator: this.programInitiator,formData}).then((url)=> {
            this.dataProgramDocuments[index].url = url
          })
        } catch(e) {
          this.$emit('onSubmitForm', {
            message: 'Erro ao enviar documento',
            color: 'error',
          })
        } finally {
          this.dataProgramDocuments[index].isUploadingDocument = false
          this.createOrUpdateDocument(this.dataProgramDocuments[index])
        }
      }
    },
    async getProgramDocuments() {
      await this.apiGetProgramDocuments(this.programId)
    },
    removeFileUrl(index){
      this.dataProgramDocuments[index].url = '-'
      this.createOrUpdateDocument(this.dataProgramDocuments[index])
    },
    async deleteDocument(id, index) {
      if(id){
        try {
          await this.apiDeleteProgramDocuments({programId: this.programId, documentId: id})
          await this.getProgramDocuments()
        } catch (e) {
          this.$emit('onSubmitForm', {
            message: 'Erro ao apagar documento.',
            color: 'error',
          })
        }
      } else {
        this.programDocuments.splice(index, 1)
      }
    },
    async createOrUpdateDocument(item){
      const data = this.dataProgramDocumentsForAPI(item)
      try
      {
        if(item.id){
          await this.apiUpdateProgramDocuments(data)
        } else {
          await this.apiCreateProgramDocuments(data)
        }
      } catch(e){
        this.$emit('onSubmitForm', {
          message: 'Erro ao atualizar documento.',
          color: 'error',
        })
      } finally {
        this.getProgramDocuments()
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style lang="scss">
.step-program-documents {
  max-width: 820px;
  margin: 0 auto;

  .v-text-field__details {
    display: none;
  }
  .button-center {
    margin: 0 auto;
  }

  .base-data-table {
    input {
      font-size: 0.8125rem
    }
    tr > td {
      height: 57px!important;
      color: var(--custom-theme-black-054) !important;
    }
    th {
      font-weight: 600 !important;
    }
    tr > td, tr > th {
      padding: 0px 10px !important;
    }
    tr > td:first-child, tr > td:last-child, tr > th:first-child, tr > th:last-child {
      padding: 0px 7px !important;
    }
    .delete {
      width: 40px;
    }
    .file {
      width: 176px;
    }
    .action-table-content .button-action-table {
      display: none;
    }
    .action-table-content:hover .button-action-table {
      display: block;
    }
  }
}
</style>

<template>
  <div class="create-program-steps d-flex justify-center">
    <v-stepper
      v-model="step"
      non-linear
      class="grayf8"
      width="820"
    >
      <v-stepper-header class="justify-center font-weight-bold">
        <v-stepper-step
          v-for="(stepCurrent, numStep) in listSteps"
          :key="numStep"
          :editable="isEditingProgram"
          edit-icon="$complete"
          :complete="step > (numStep + 1)"
          class="pr-1 step-content"
          :step="numStep + 1"
        >
          <template v-slot:default>
            <span
              v-if="isEditingProgram"
              class="step-name"
            >
              {{ stepCurrent.name }}
            </span>
          </template>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-for="(stepCurrent, numStep) in listSteps"
          :key="numStep"
          :step="numStep + 1"
          class="pa-0"
        >
          <component
            :is="stepCurrent.component"
            v-if="step === (numStep + 1)"
            @onNextStep="nextStep"
            @onPreviousStep="previousStep"
            @onSubmitForm="showFeedback"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import StepProgramName from './StepProgramName'
import StepProgramAssets from './StepProgramAssets.vue'
import StepProgramParticipants from './StepProgramParticipants'
import StepProgramParticipantsDocuments from './StepProgramParticipantsDocuments'
import StepProgramDocuments from './StepProgramDocuments'
import StepProgramConfiguration from './StepProgramConfiguration'
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  name: 'CreateProgramSteps',
  components: { StepProgramName, StepProgramAssets, StepProgramParticipants, StepProgramParticipantsDocuments, StepProgramDocuments, StepProgramConfiguration, BaseSnackbar },
  mixins:  [ BaseSnackbarConfiguration ],
  data() {
    return {
      step: 1,
      programId: '',
      listSteps: [
      {
        name: 'Iniciais',
        component: 'StepProgramName'
      },
      {
        name: 'Ativos',
        component: 'StepProgramAssets'
      },
      {
        name: 'Participantes',
        component: 'StepProgramParticipants'
      },
      {
        name: 'Doc Participantes',
        component: 'StepProgramParticipantsDocuments'
      },
      {
        name: 'Documentos',
        component: 'StepProgramDocuments'
      },
      {
        name: 'Configurações',
        component: 'StepProgramConfiguration'
      }
    ],
      isEditingProgram: false,
    }
  },
  created(){
    if(this.$route.params.id){
      this.programId = this.$route.params.id
      this.setProgramId(this.programId)
      this.isEditingProgram = true
    }
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setUseActionFooter", "setProgramId"]),
    nextStep(step) {
      this.step = step
    },
    previousStep(step) {
      if(this.step == 1)
        return
      this.step = step
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    this.setProgramId(null)
    next()
  }
}
</script>

<style scoped>
.v-divider{
  width: 30px !important;
  flex: none !important;
  border-color: var(--v-primary-base) !important;
  border-width: medium;
}

.step-content:hover {
  background: none!important;
}

.step-name {
  font-weight: normal;
  color: var(--custom-theme-black-038);
  font-size: small;
}

.step-content .step-name {
  display: none;
}

.step-content:hover .step-name {
  display: block;
}
</style>

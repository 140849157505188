<template>
  <div class="operation-info-step-detail">
    <v-navigation-drawer
      :value="isGetCurrentOperationInfoStepNode"
      app
      right
      clipped
      ligth
      fixed
      width="428"
      mobile-breakpoint="990"
      disable-resize-watcher
      class="app-navigation"
    >
      <v-toolbar
        color="primary mb-6"
        dark
      >
        <v-toolbar-title class="font-weight-regular">
          Detalhamento
        </v-toolbar-title>
        <v-spacer />
        <BaseButton
          :has-icon="true"
          @click="closeOperationInfoStepDetail"
        >
          <v-icon>close</v-icon>
        </BaseButton>
      </v-toolbar>
      <v-container
        v-if="isGetCurrentOperationInfoStepNode"
        py-0
        px-6
      >
        <v-row>
          <v-col class="pb-0">
            <div class="operation-info-step-detail__line">
              <h6
                class="font-weight-regular mb-2 title"
                style="background-color: transparent !important"
                :class="[currentOperationInfoStepNode.status.toLowerCase()]"
                :data-attr-operation-info-icon="parseIconCodeStepDetail"
              >
                {{ currentOperationInfoStepNode.name }}
              </h6>
              <div class="operation-info-step-detail__line__text date pl-4">
                Início: {{ currentOperationInfoStepNode.updatedAt | getTime }}
              </div>
              <hr class="operation-info-step-detail__line--divider my-6">
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <div class="operation-info-step-detail__line subtitle-1">
              <p class="font-weight-regular mb-6">
                Detalhes
              </p>
              <div class="operation-info-step-detail__line__text pb-6">
                <p>{{ currentOperationInfoStepNode.message }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: "OperationInfoStepDetail",
  computed: {
    ...mapState({
      currentOperationInfoStepNode: (state) => {
        return state.operationInfo.currentOperationInfoStepNode
      },
      currentOperationInfoStepNodeElement: (state) => {
        return state.operationInfo.currentOperationInfoStepNodeElement
      }
    }),
    isGetCurrentOperationInfoStepNode(){
      return !!this.currentOperationInfoStepNode
    },
    parseIconCodeStepDetail(){
      switch(this.currentOperationInfoStepNode.status.toLowerCase()){
        case "done":
          return "check_circle"
        case "error":
          return "cancel"
        case "pending":
          return "lens"
        case "executing":
          return "timelapse"
        default:
          return ""
      }
    }
  },
  methods: {
    ...mapMutations(['setCurrentOperationInfoStepNode', 'setCurrentOperationInfoStepNodeElement']),
    closeOperationInfoStepDetail(){
      if(this.currentOperationInfoStepNodeElement){
        this.currentOperationInfoStepNodeElement.classList.remove('focus')
        if(this.currentOperationInfoStepNodeElement.parentNode){
          this.currentOperationInfoStepNodeElement.parentNode.classList.remove('focus')
        }
      }
      this.setCurrentOperationInfoStepNode(null)
      this.setCurrentOperationInfoStepNodeElement(null)
    }
  }
}
</script>

<style>
  .done:before {
    color: var(--v-ok-base) !important;
  }

  .error:before {
    color: var(--v-error-base) !important;
  }

  .running:before {
    color: var(--v-running-base) !important;
  }

  .title {
    background-color: transparent !important;
  }

  .operation-info-step-detail__line {
    padding-left: 40px;
    word-break: break-word;
  }

  .operation-info-step-detail__line *[data-attr-operation-info-icon] {
    position: relative;
  }

  .operation-info-step-detail__line *[data-attr-operation-info-icon]:before{
    content: attr(data-attr-operation-info-icon);
    color: inherit;
    font-family: "Material Icons";
    font-size: 24px;
    left: -40px;
    position: absolute;
    top: 0;
  }

  .operation-info-step-detail__line--divider {
    border-color: var(--custom-theme-black-020);
    border-width: thin 0 0 0;
    width: calc(100% + 24px);
  }

  .operation-info-step-detail__line__text {
    color: var(--custom-theme-black-038);
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 0;
    position: relative;
  }

  .operation-info-step-detail__line__text > * *:not(i) {
    display: block;
  }

  .operation-info-step-detail__line__text > * *:first-letter {
    text-transform: uppercase;
  }

  .operation-info-step-detail__line__text.date > * {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .operation-info-step-detail__line__text.date:before,
  .operation-info-step-detail__line__text.file:before {
    font-family: "Material Icons";
    font-size: 12px;
    left: 0;
    position: absolute;
    top: 3px;
  }

  .operation-info-step-detail__line__text.date:before {
    content: "access_time";
  }

  .operation-info-step-detail__line__text.file:before {
    content: "insert_drive_file";
  }
</style>


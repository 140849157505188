<template>
  <BaseContainer
    class="executions-list"
    :permission-list="['owner']"
  >
    <template v-slot:header-title>
      {{ getTextMenu('executions',`Operações ${programName}`) }}
    </template>
    <template>
      <v-chip
        class="ma-2 ml-0 font-weight-bold"
        label
        text-color="primary"
        color="secondary"
      >
        <v-icon
          left
          small
        >
          calendar_today
        </v-icon>
        Data das operações: {{ dateToday | getDate }}
      </v-chip>
      <v-tooltip
        bottom
      >
        <template
          v-slot:activator="{ on }"
        >
          <span
            v-on="on"
          >
            <v-chip
              class="ml-2 pa-2 font-weight-bold"
              label
              text-color="primary"
              color="secondary"
              :disabled="loadPage"
              @click="getExecutionsProgram()"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-chip>
          </span>
        </template>
        <span>Atualizar operações</span>
      </v-tooltip>
    </template>
    <template>
      <v-row>
        <v-card
          v-for="(card, index) in cardsOperations"
          :key="index"
          :height="80"
          :width="300"
          class="card"
          :class=" {'card-dark' : card.color === 'primary', 'ml-0' : index === 0, 'activate-hover': card.link } "
          outlined
          :to="card.link ? card.link : null"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title class="py-2">
              <div>
                <h3
                  class="subtitle-2 font-weight-bold"
                  :class=" {'headline' : card.color !== 'primary'} "
                >
                  {{ card.title }}
                </h3>
                <div class="app-font-semibold mt-2 text-h5">
                  {{ card.value }}
                </div>
              </div>
            </v-card-title>
            <v-card-actions v-if="card.link">
              <v-icon
                :class="{'card-dark' : card.color === 'primary'}"
                style="align-self: end;"
              >
                mdi mdi-export
              </v-icon>
            </v-card-actions>
          </div>
        </v-card>
      </v-row>
    </template>
    <div class="mb-6">
      <h2 class="headline app-headline app-font-semibold">
        Lista de operações
      </h2>
      <p class="body-1">
        Operações das últimas 24 horas
      </p>
    </div>
    <BaseCard
      base-card-container-classes="mt-4"
      :has-main-container="true"
    >
      <BaseDataTable
        :headers="headers"
        :items="executionsProgram.executions"
        background-color="transparent"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="item in scopedItems"
            :key="item.index"
          >
            <td>
              {{ item.item.data && item.item.data.name ? item.item.data.name : item.item.description }}
            </td>
            <td width="53%">
              <v-progress-linear
                :value="getStatusProgress(item.item.status)"
                :color="getStepColor(item.item.status)"
                rounded
                height="25"
                :indeterminate="item.item.status === 'RUNNING'"
              >
                <template v-slot:default="{ value }">
                  <p>{{ getStepText(item.item.status) }}</p>
                  <p v-if="item.item.status !== 'RUNNING'">{{ Math.ceil(value) }}%</p>
                </template>
              </v-progress-linear>
            </td>
            <td class="text-right">
              <BaseButton
                :has-icon="true"
                class="ma-0"
                @click="$router.push({ path: `/executions/detail/${item.item.id}`})"
              >
                <v-icon>mdi-export</v-icon>
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseDataTable from '../BaseDataTable'

export default {
  components: { BaseDataTable },
  data() {
    return {
      sumOfTotalAmount: 0,
      sumOfTotalWriteOff: 0,
      itemsFinished: 0,
      headers: [
        {
          text: 'Últimas operações',
          value: 'description',
          class: '',
          sortable: false
        },
        {
          hidden: false,
          text: 'Status',
          value: 'status',
          class: '',
          sortable: false
        },
        {
          hidden: false,
          text: 'Detalhes',
          value: 'id',
          class: 'text-end',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState({
      programInfo: (state) => state.platformProgram.programInfo,
      executionsProgram: (state) => state.operationInfo.executionsProgram,
      loadPage: (state) => state.loadPage
    }),
    programName() {
      return this.programInfo ? this.programInfo.name : ''
    },
    dateToday() {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    totalItemsExecutions(){
      return this.executionsProgram && this.executionsProgram.totalItems ? this.executionsProgram.totalItems : 0
    },
    cardsOperations(){
      return [
        {
          title: "Operações finalizadas",
          value: `${this.itemsFinished} de ${this.totalItemsExecutions}`,
          color: "primary",
        },
        {
          title: "Valor baixado",
          value: this.$options.filters.formatCurrency(this.sumOfTotalWriteOff),
          link: "/executions/dashboard/?context=total_write_off",
        },
        {
          title: "Valor cedido",
          value: this.$options.filters.formatCurrency(this.sumOfTotalAmount),
          link: "/executions/dashboard/?context=total_amount",
        }
      ]
    }
  },
  watch: {
    executionsProgram: {
      handler() {
        this.sumTotalExecutions()
      },
      deep: true,
    },
  },
  created(){
    this.sumTotalExecutions()
  },
  methods: {
    ...mapActions(["apiGetExecutionsProgram"]),
    sumTotalExecutions(){
      this.sumOfTotalAmount = 0
      this.sumOfTotalWriteOff = 0
      this.itemsFinished = 0
      this.executionsProgram.executions.forEach(item => {
        if(item.data.total_amount){
          this.sumOfTotalAmount = this.sumOfTotalAmount + parseFloat(item.data.total_amount)
        }

        if(item.data.total_write_off){
          this.sumOfTotalWriteOff = this.sumOfTotalWriteOff + parseFloat(item.data.total_write_off)
        }

        if(item.status.toUpperCase() === "OK"){
          this.itemsFinished++
        }
      });
    },
    getStepText(status){
      const text = {
        "ok" : "Finalizado",
        "error" : "Alerta",
        "running" : "Em progresso",
        "pending" : "Não iniciado"
      }
      return text[status.toLowerCase()]
    },
    getStepColor(status){
      const color = {
        "ok": "#4CAF50",
        "error": "#EB6868",
        "running": "#FB8C00",
        "pending": "#00000061"
      }
      return color[status.toLowerCase()]
    },
    getStatusProgress(status){
      const progress = {
        "ok": 100,
        "error": 100,
        "running": 0,
        "pending": 0
      }
      return progress[status.toLowerCase()]
    },
    async getExecutionsProgram(){
      await this.apiGetExecutionsProgram()
    }
  }
}
</script>

<style lang="scss">
  .executions-list {
    .card {
      border-radius: 8px;
      border: 1px solid #293273;
      color: #293273;
      margin: 20px 5px;
    }

    .card-dark {
      background-color: #293273;
      color: white;
    }
    .v-progress-linear--rounded {
      border-radius: 15px;
    }
    .v-progress-linear__content {
      justify-content: space-between !important;
      padding: 0 10px;
    }
    .v-progress-linear__indeterminate .long,
    .v-progress-linear__indeterminate .short {
      animation-duration: 10s;
    }
    .activate-hover:hover {
      background-color: #f8f8f8;
    }
  }
</style>

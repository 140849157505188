<template>
  <div class="platform-developer">
    <BaseContainer
      :permission-list="['none']"
      :active-spacer="true"
    >
      <template v-slot:header-title>
        Desenvolvimento
      </template>
      <template v-slot:header-title-description>
        Mutant Portal  - Configure e gerencie os parâmetros dos programas.
      </template>

      <v-row>
        <v-col
          sm="2"
          xl="2"
          class="pa-0"
        >
          <v-list
            dense
          >
            <v-list-item-group
              v-model="selectedItem"
            >
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                class="px-0"
                :disabled="item.disabled"
              >
                <BaseLink
                  :to="`/developer/${item.to}`"
                  style="width: 100%; height: 100%; min-height:40px;"
                >
                  <v-list-item-content class="px-2 py-3">
                    <v-list-item-title
                      :class="{'color--text-black-020': item.disabled, 'color--text-black-054': !item.disabled}"
                      v-text="item.name"
                    />
                  </v-list-item-content>
                </BaseLink>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col
          sm="10"
          xl="10"
        >
          <router-view @onSubmitForm="showFeedback" />
        </v-col>
      </v-row>
    </BaseContainer>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  name: 'Developer',
  components: { BaseSnackbar },
  mixins : [ BaseSnackbarConfiguration ],
  data() {
    return {
      selectedItem: 0,
      menuItems: [
        {
          name: 'Arquivos Recebidos',
          to: 'incoming-files',
          disabled: false,
        },
        {
          name: 'Tarefas Agendadas',
          to: 'schedules',
          disabled: false
        },
        {
          name: 'Originadores',
          to: 'initiators',
          disabled: false
        },
        {
          name: 'Regras',
          to: 'rules',
          disabled: false
        },
        {
          name: 'Contas',
          to: 'accounting',
          disabled: true
        },
        {
          name: 'Tipos de Eventos',
          to: 'event-types',
          disabled: false
        },
        {
          name: 'Processadores',
          to: 'event-processors',
          disabled: false
        },
        {
          name: 'Meus Processadores',
          to: 'process-configuration',
          disabled: true
        }
      ]
    }
  },
  async created() {
    this.changeSelectedItem()
  },
  methods: {
    changeSelectedItem(){
      const routePath = this.$route.path.split('/').reverse()[0]
      const routeIndex = this.menuItems.findIndex(el => el.to === routePath)
      this.selectedItem = routeIndex > -1 ? routeIndex : 0
    }
  }
}
</script>

<style scoped>
.platform-developer .v-list {
  background: transparent;
}

.v-list-item-group .v-list-item--active >>> .v-list-item__title {
  font-weight: bold;
}
</style>

<template>
  <v-app class="grayf8">
    <v-progress-linear
      v-if="loadPage"
      v-model="progressLoadPage"
      color="primary"
      height="2"
      class="app-load-page"
    />
    <TheHeader
      :is-envelope-path="!disableNavigation"
      :header-title="headerTitle"
      :header-bg="headerBg"
      :header-use-box-shadow="headerUseBoxShadow"
      @onClickButtonMenuHeader="drawerNavigation = !drawerNavigation"
    />
    <TheNavigation
      v-if="!disableNavigation"
      v-model="drawerNavigation"
      :navigation-content-itens="navigationContentItens"
      :navigation-bg="navigationBg"
      :navigation-mini="navigationMini"
      :navigation-context="navigationContext"
    />
    <v-main>
      <v-container
        class="mt-4"
        fluid
      >
        <ModalTermsOfUse 
          :modal-active="activeModalTermUse" 
        />
        <router-view />
        <BaseSnackbar
          :active-snackbar="feedbackPermissionErrorMessage.active"
          type-snackbar="error"
          :text-snackbar="feedbackPermissionErrorMessage.msg"
          @closeSnackbar="feedbackPermissionErrorMessage = { active : false, msg: '' }"
        />
        <BaseSnackbar
          v-if="!feedbackPermissionErrorMessage.active && showCookieSnackbar"
          :active-snackbar="showCookieSnackbar"
          type-snackbar="infoPlatform"
          text-snackbar="Essa plataforma utiliza cookies para possibilitar sua navegação."
          :icon-snackbar="false"
          :title-snackbar="'Cookies'"
          :text-button-action="'Ok'"
          :button-action="notificationCookies"
        />
      </v-container>
    </v-main>
    <TheFooter
      v-if="!useActionFooter"
      :no-padding-x="!disableNavigation"
      :padding-left="drawerNavigation"
      :padding-right="isDrawerRightActived"
      :navigation-mini="navigationMini"
    />
  </v-app>
</template>

<script>
import TheHeader from "./components/TheHeader"
import TheNavigation from "./components/TheNavigation"
import TheFooter from "./components/TheFooter"
import { mapState, mapMutations } from "vuex"
import BaseSnackbar from './components/BaseSnackbar'
import ModalTermsOfUse from "./components/Platform/ModalTermsOfUse"

export default {
  name: "App",
  components: { TheHeader, TheNavigation, TheFooter, BaseSnackbar, ModalTermsOfUse },
  data() {
    return {
      drawerNavigation: (() => {
        return window.innerWidth > 990
      })(),
      showCookieSnackbar: !localStorage.getItem("acceptedCookies")
    }
  },
  computed: {
    ...mapState({
      keycloak: (state) => state.auth.keycloak.tokenParsed,
      isDrawerRightActived: (state) => {
        return state.operationRegister.isClickButtonFilterOperationRegister
      },
      loadPage: (state) => {
        return state.loadPage
      },
      progressLoadPage: (state) => {
        return state.progressLoadPage
      },
      disableNavigation: (state) => {
        return state.disableNavigation
      },
      headerTitle: (state) => {
        return state.theHeader.headerTitle
      },
      headerBg: (state) => {
        return state.theHeader.headerBg
      },
      headerUseBoxShadow: (state) => {
        return state.theHeader.headerUseBoxShadow
      },
      navigationContentItens: (state) => {
        return state.theNavigation.navigationContentItens
      },
      navigationBg: (state) => {
        return state.theNavigation.navigationBg
      },
      navigationMini: (state) => {
        return state.theNavigation.navigationMini
      },
      navigationContext: (state) => {
        return state.theNavigation.navigationContext
      },
      useActionFooter: (state) => {
        return state.useActionFooter
      },
      permissionErrorMessage: (state) => { return state.permissionErrorMessage},
    }),
    feedbackPermissionErrorMessage: {
      get() {
        return this.permissionErrorMessage
      },
      set(value) {
        this.setPermissionErrorMessage(value)
      }
    },
    activeModalTermUse(){
      return this.keycloak['acceptedTerms'] ? false : true
    }
  },
  watch: {
    $route(to, from){
      this.$ua.trackView(to.path, true)
    }
  },
  methods: {
    ...mapMutations(['setPermissionErrorMessage']),

    notificationCookies() {
      localStorage.setItem("acceptedCookies", "true")
      this.showCookieSnackbar = false
    }
  }
}
</script>

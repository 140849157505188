import api from '../../services/api';
import {utilsCreateTemporaryLinkToDownload} from "@/utils";

export default {
  state: {
    initiatorsFiles: [],
    uploadedFiles: [],
    reportFiles: [],
    incomingFiles: []
  },
  getters: {
    getUploadedFiles(state) {
      return state.uploadedFiles
    },
  },
  mutations: {
    setUploadedFiles(state, payload) {
      state.uploadedFiles = payload
    },
    setReportFiles(state, payload) {
      state.reportFiles = payload
    },
    setIncomingFiles(state, payload) {
      state.incomingFiles = payload
    }
  },
  actions: {
    apiGetProgramFiles({commit},payload) {
      return new Promise((resolve, reject) => {
        if(payload.page){
          payload.page = payload.page - 1
        }
        api.get('program_files', { params: payload }).then((data) => {
          const response = data.data.length > 0 ? [...data.data] : null
          if(payload.prefix == 'uploaded-files'){
            commit('setUploadedFiles', data.data)
          } else {
            commit('setReportFiles', data.data)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    apiGetDownloadFile({commit},payload) {
      return new Promise((resolve, reject) => {
        api.get(`incoming_files/${payload.uuid}`,
          { responseType: 'blob' }).then(data => {
          utilsCreateTemporaryLinkToDownload(payload.fileName, data.data,'application/zip','zip')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async apiGetIncomingFiles({ commit }, payload) {
      const data = await api.get(`/incoming_files`, { params: payload })
      commit('setIncomingFiles', data.data)
      return data
    },
  }
}

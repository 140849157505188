<template>
  <div>
    <v-menu
      offset-y
      :attach="attachMenu"
      :right="alignRight"
      :left="alignLeft"
      :max-height="500"
      :max-width="300"
      :min-width="300"
      :close-on-content-click="false"
      class="menu-config-form-asset"
    >
      <template v-slot:activator="{ on }">
        <BaseButton
          :has-icon="true"
          button-class="ma-0"
          v-on="on"
        >
          <v-icon>settings</v-icon>
        </BaseButton>
      </template>
      <v-card
        v-if="!configSchema"
        scrollable
      >
        <h3 class="font-weight-bold app-headline py-4 px-4 text-left">
          {{ menuTitle }}
        </h3>
        <v-divider />
        <v-card-text
          class="pa-0"
          style="height: 350px; overflow-y: scroll"
        >
          <h3
            class="font-weight-bold app-headline pt-4 px-4 text-left"
          >
            Schema
          </h3>
          <v-list
            v-if="orderedSchemaFields.length"
            class="pl-4"
          >
            <draggable
              v-model="orderedSchemaFields"
              @change="draggableChange"
            >
              <v-list-item
                v-for="item, index in orderedSchemaFields"
                :key="index"
                class="list-asset-fields"
              >
                <div class="pl-0">
                  <v-icon
                    class="color--text-black-020 mr-1"
                  >
                    drag_indicator
                  </v-icon>
                  <span
                    style="font-size: 15px;"
                    class="color--text-black-054 font-weight-regular"
                  >
                    {{ item.name }}
                  </span>
                </div>
                <div>
                  <BaseButton
                    :has-icon="true"
                    @click="menuConfigFieldSchema(item)"
                  >
                    <v-icon
                      class="color--text-black-020"
                    >
                      chevron_right
                    </v-icon>
                  </BaseButton>
                </div>
              </v-list-item>
            </draggable>
          </v-list>
          <p
            v-else
            class="d-flex align-center justify-center text-center my-4"
          >
            Adicione um campo para criar o schema.
          </p>
          <v-divider />

          <h3
            class="font-weight-bold app-headline py-4 px-4 text-left"
          >
            Adicionar campo
          </h3>

          <BaseInput
            ref="filter"
            v-model="filter"
            label="Digite o nome do campo"
            class="px-4"
            clearable
            @input="getSchemas(filter)"
          />
          <v-list class="pt-0 pb-4">
            <v-list-item
              v-for="item, index in filteredSchemasField"
              :key="index"
              class="list-asset-fields"
              @click="addFieldInSchema(item)"
            >
              <span
                style="font-size: 15px;"
                class="color--text-black-054 px-4 font-weight-regular"
              >
                {{ item.name }}
              </span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-form
        ref="formConfigSchemas"
        v-model="valid"
      >
        <v-card
          v-if="configSchema"
          class="mx-auto"
          scrollable
        >
          <v-card-actions
            class="pa-0 ml-4"
            style="display: flex; justify-content: space-between;"
          >
            <h3 class="font-weight-bold app-headline">
              {{ fields.name }}
            </h3>
            <BaseButton
              :has-icon="true"
              @click="openModalDeleteFieldSchema(fields)"
            >
              <v-icon>
                delete
              </v-icon>
            </BaseButton>
          </v-card-actions>
          <v-divider />
          <v-card-actions
            class="py-1"
            style="background-color: var(--custom-theme-black-005) !important;"
          >
            <v-spacer />
            <BaseButton
              size="small"
              type="text"
              color="primary"
              @click="clearData()"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              size="small"
              :is-disabled="!valid"
              @click="saveMenuListFieldSchema(fields.key)"
            >
              Salvar
            </BaseButton>
          </v-card-actions>
          <v-card-text
            class="pa-0 pb-4"
            style="height: 350px; overflow-y: scroll"
          >
            <div class="mx-4 my-4 color--text-black-054 text-left">
              <p>
                <span class="font-weight-bold">Key:</span> {{ fields.key || fields.field.key }}
              </p>
              <p v-if="fields.prefix || (fields.field && fields.field.prefix) ">
                <span class="font-weight-bold">Prefix:</span> {{ fields.prefix || fields.field.prefix }}
              </p>
            </div>
            <v-divider class="mb-4" />
            <BaseInput
              v-if="formFieldList.includes('name')"
              v-model="fields.name"
              label="Name"
              class="px-4"
              clearable
              :rules="[isValid]"
            />
            <v-select
              v-if="formFieldList.includes('input_type')"
              v-model="fields.input_type"
              :items="listInputType"
              class="px-4"
              label="Input type"
              :rules="[isValid]"
            />
            <div
              v-if="formFieldList.includes('datasource')"
              class="px-4 pt-4 pb-4"
              :class="{ 'mb-4': !datasourceIsString }"
              :style="{ 'background-color': datasourceIsString ? 'unset' : 'var(--v-grayf8-base)'}"
            >
              <BaseInput
                v-if="datasourceIsString"
                v-model="fields.datasource"
                dense
                label="Datasource"
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <BaseButton
                        :has-icon="true"
                        color="primary"
                        class="ma-0 pa-0"
                        v-on="on"
                        @click="changeDatasourceType(datasourceIsString)"
                      >
                        <v-icon small>
                          mdi-swap-horizontal
                        </v-icon>
                      </BaseButton>
                    </template>
                    Alterar datasource para array
                  </v-tooltip>
                </template>
              </BaseInput>
              <template v-else>
                <v-autocomplete
                  v-model="datasourceList"
                  :items="datasourceList"
                  label="Datasource"
                  item-text="name"
                  item-value="value"
                  hint="Adicione itens através dos campos 'nome' e 'valor' abaixo"
                  persistent-hint
                  return-object
                  multiple
                  chips
                  small-chips
                  @change="changeDatasourceList()"
                >
                  <template v-slot:append-outer>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <BaseButton
                          :has-icon="true"
                          color="primary"
                          class="ma-0 pa-0"
                          v-on="on"
                          @click="changeDatasourceType(datasourceIsString)"
                        >
                          <v-icon small>
                            mdi-swap-horizontal
                          </v-icon>
                        </BaseButton>
                      </template>
                      Alterar datasource para string
                    </v-tooltip>
                  </template>
                </v-autocomplete>
                <div
                  class="app-align-items-center mt-4"
                >
                  <BaseInput
                    v-model="datasourceObject.name"
                    label="Nome exibição"
                    class="mr-2"
                    hide-details
                    dense
                    clearable
                  />
                  <BaseInput
                    v-model="datasourceObject.value"
                    label="Valor item"
                    class="ml-2"
                    hide-details
                    dense
                    clearable
                  />
                  <BaseButton
                    :is-disabled="!datasourceObject.value || !datasourceObject.name"
                    color="primary"
                    class="ma-0"
                    :has-icon="true"
                    @click="addDatasource(datasourceObject)"
                  >
                    <v-icon small>
                      mdi-plus-box
                    </v-icon>
                  </BaseButton>
                </div>
              </template>
            </div>
            <v-select
              v-if="formFieldList.includes('condition')"
              v-model="fields.condition"
              :items="listCondition"
              class="px-4"
              label="Condition"
              :rules="[isValid]"
            />
            <BaseInput
              v-if="formFieldList.includes('type')"
              v-model="fields.type"
              label="Type"
              class="px-4"
              clearable
            />
            <v-checkbox
              v-if="formFieldList.includes('required')"
              v-model="fields.required"
              label="Required"
              class="pl-4"
            />
            <v-checkbox
              v-if="formFieldList.includes('read_only')"
              v-model="fields.read_only"
              label="Read only"
              class="pl-4"
            />
            <v-checkbox
              v-if="formFieldList.includes('hidden')"
              v-model="fields.hidden"
              label="Hidden"
              class="pl-4"
            />
            <v-checkbox
              v-if="formFieldList.includes('multi_valued')"
              :input-value="fields.multi_valued"
              label="Multivalued"
              class="pl-4"
              @change="changeCheckbox(fields.multi_valued)"
            />
            <BaseInput
              v-if="formFieldList.includes('help_text')"
              v-model="fields.help_text"
              label="Help text"
              class="px-4"
              clearable
            />
            <BaseInput
              v-if="formFieldList.includes('validation')"
              v-model="fields.validation"
              label="Validation"
              class="px-4"
              clearable
            />
            <v-checkbox
              v-if="formFieldList.includes('default')"
              v-model="defaultCheckbox"
              label="Default"
              class="pl-4"
              @change="changeDefaultCheckbox"
            />
            <template v-if="defaultCheckbox">
              <template v-if="fields.input_type == 'date'">
                <v-select
                  v-model="modelDefaultType"
                  :items="optionsDefaultType"
                  item-text="text"
                  item-value="type"
                  class="px-4"
                  :rules="[isValid]"
                  label="Selecione tipo de data"
                  @change="setDefaultType"
                />

                <v-radio-group
                  v-if="modelDefaultType"
                  v-model="modelRadioDate"
                  hide-details
                  class="my-3 px-4"
                  :rules="[isValid]"
                  @change="changedModelRadioDate"
                >
                  <v-radio
                    v-for="option, index in radioDateOptions"
                    :key="index"
                    :label="getRadioDateOptions(option)"
                    :value="option"
                  />
                </v-radio-group>
                <div
                  v-if="modelRadioDate == 'customDate'"
                  class="app-align-items-center px-4 mr-12"
                >
                  <p>
                    {{ modelDefaultType == 'past' ? 'Últimos' : 'Próximos' }}
                  </p>
                  <v-text-field
                    v-model="modelDefaultCustomDate"
                    class="px-4"
                    :rules="[isValid, isOnlyNumber]"
                  />
                  <p>
                    Dias
                  </p>
                </div>
              </template>
              <template v-else>
                <v-combobox
                  v-model="fields.default"
                  :items="fields.default"
                  label="Default"
                  hint="Ao digitar cada valor aperte 'enter' para adicionar."
                  persistent-hint
                  multiple
                  chips
                  :rules="[isValidArray]"
                  name="qa-id-name-default"
                  class="px-4 pt-0"
                />
              </template>
            </template>
          </v-card-text>
        </v-card>
      </v-form>
    </v-menu>
    <v-dialog
      v-model="isModalActive"
      persistent
      max-width="560"
    >
      <BaseModal
        :show-indeterminate-progress="modalDeleteSchemaField.showIndeterminateProgress"
        :top-slot-content-title="modalDeleteSchemaField.topSlotContentTitle"
        :remove-button-close="modalDeleteSchemaField.removeButtonClose"
        :middle-slot-content-text-highlight="modalDeleteSchemaField.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalDeleteSchemaField.middleSlotContentText"
        :is-middle-slot-content-text-centered="false"
        :bottom-buttons-align="modalDeleteSchemaField.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalDeleteSchemaField.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalDeleteSchemaField.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalDeleteSchemaField.removeButtonCancel"
        :remove-button-confirm="modalDeleteSchemaField.removeButtonConfirm"
        @on-cancel="isModalActive = false"
        @on-confirm="deleteFieldSchemaForm"
      />
    </v-dialog>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseInput from '../Input/BaseInput'
import BaseModal from '../BaseModal'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar'
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import ProgramRoles from "@/mixins/ProgramRoles";

export default {
  components: { BaseInput, BaseModal, BaseSnackbar, draggable },
  mixins: [ BaseSnackbarConfiguration, ProgramRoles ],
  props:{
    menuAlign: {
      type: String,
      default: 'right',
      required: false,
      validator: (value) => ['right', 'left'].includes(value)
    },
    menuTitle: {
      type: String,
      required: true,
    },
    scopeSchema: {
      type: String,
      required: true
    },
    configList: {
      type: Array,
      required: true
    },
    formFieldList: {
      type: Array,
      required: true
    },
    attachMenu: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    filter: '',
    orderedSchemaFields: [],
    configSchema: false,
    valid: false,
    listInputType: [
      'text',
      'select',
      'date',
      'file',
      'amount',
      'percent'
    ],
    listCondition: [
      'between',
      'contains',
      'equals',
      'starts_with'
    ],
    optionsDefaultType: [
      {type: 'past', text: 'Data Passada'},
      { type: 'future', text: 'Data Futura'}
    ],
    fields: {},
    isModalActive: false,
    modalDeleteSchemaField: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Excluir campo do Schema",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: "Excluir este campo?",
      middleCheckAgree: null,
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "Confirmar",
      removeButtonCancel: false,
      removeButtonConfirm: false
    },
    currentSchema: null,
    activeFieldToDelete: null,
    datasourceIsString: true,
    datasourceObject: {},
    datasourceList: [],
    radioDateOptions: [ "7", "15", "30", "customDate"],
    modelRadioDate: '',
    modelDefaultType: '',
    modelDefaultCustomDate: '',
    defaultCheckbox: false
   }),
   computed:{
    ...mapState({
      schemasFields: (state) => state.platformProgram.schemasFields.sort((a, b) => a.name.localeCompare(b.name)),
      configSchemaRole: (state) => state.platformConfiguration.configSchemaRole,
      programInfo: (state) => state.platformProgram.programInfo
    }),
    alignLeft(){
      return this.menuAlign === 'left' ? true : false
    },
    alignRight(){
      return this.menuAlign === 'right' ? true : false
    },
    filteredSchemasField(){
      return this.schemasFields.filter(field =>
        this.orderedSchemaFields.findIndex(elem =>
        ((elem.key && elem.key === field.key) || (elem.field && elem.field.key === field.key))
        ) == -1
      )
    }
  },
  watch: {
    configSchemaRole(value){
      this.getSchemas(null, value)
    },
    configList: {
      handler(currValue) {
       this.orderedSchemaFields = currValue
      },
      deep: true
    }
  },
  mounted() {
    this.getSchemas(null, this.configSchemaRole)
  },
  methods: {
    ...mapActions(['apiGetSchemasFields']),
    ...mapActions('programMovements', ['apiGetSchemas']),
    changedModelRadioDate(){
      this.modelDefaultCustomDate = ''
    },
    getRadioDateOptions(option){
      if(option == 'customDate'){
        return 'Personalizado'
      } else {
        return `${this.modelDefaultType == 'past' ? 'Últimos' : 'Próximos'} ${option} dias`
      }
    },
    changeDefaultCheckbox(value){
      if(!value){
        this.fields.default = ''
        this.modelRadioDate = ''
        this.modelDefaultType = ''
        this.modelDefaultCustomDate = ''
      } else {
        this.fields.default = []
      }
    },
    setDefaultType(type) {
      this.fields.default = Object.assign({},
        {
          ...this.fields.default,
          type: type
        }
      )
    },
    changeCheckbox(value) {
      return this.fields.multi_valued = !value
    },
    addDatasource(value){
      this.datasourceList.push(value)
      this.datasourceObject = {}
      this.changeDatasourceList()
    },
    changeDatasourceList(){
      this.fields.datasource = JSON.stringify(this.datasourceList)
    },
    changeDatasourceType(value){
      this.datasourceIsString = !value
    },
    async getSchemas(filter = null, role = null) {
      await this.apiGetSchemasFields(filter)
      this.currentSchema = await this.apiGetSchemas({ scope: this.scopeSchema, role: this.configSchemaRole })
    },
    draggableChange(){
      this.orderedSchemaFields.forEach((field, index) => {
        field.sequence = index
      });
      this.$emit('changeOrderItemSchema', {
        role: this.configSchemaRole,
        schemaName: this.currentSchema ? this.currentSchema.data.name : '',
        programName: this.programInfo.name,
        data: this.orderedSchemaFields
      })
    },
    parseValueDatasource(field){
      let fieldDatasource = field.field ? field.field.datasource : field.datasource 
      if(fieldDatasource.startsWith('[')){
        try {
          this.datasourceIsString = false
          this.datasourceList = JSON.parse(this.fields.datasource)
        } catch(error) {
          this.showFeedback({
          color: "error",
          message: 'Erro no campo datasource!',
        })
        }

      }
    },
    parseValueDefault(field){
      if(this.fields.default){

        if(field.default.startsWith('{')){
          this.defaultCheckbox = true
          this.fields.default = JSON.parse(field.default)
          this.modelDefaultType = this.fields.default.type,
          this.modelDefaultCustomDate = this.radioDateOptions.includes(this.fields.default.date) ? null : this.fields.default.date
          this.modelRadioDate = this.radioDateOptions.includes(this.fields.default.date) ? this.fields.default.date : 'customDate'

        } else if (field.default.startsWith('[')){
          this.fields.default = JSON.parse(field.default)
          this.defaultCheckbox = this.fields.default.length ? true : false
        }
      } else {
        this.defaultCheckbox = false
        this.fields.default = []
      }
    },
    menuConfigFieldSchema(field){
      if(field.field){
        Object.assign(this.fields, {...field})
        this.$set(this.fields, 'input_type', field.field.input_type)
        this.$set(this.fields, 'sequence', field.field.sequence)
        this.$set(this.fields, 'multi_valued', field.field.multi_valued === "true")
        this.$set(this.fields, 'datasource', field.field.datasource)
      } else {
        Object.assign(this.fields, {
          ...field,
          required: field.required === "true",
          read_only: field.read_only === "true",
          hidden: field.hidden === "true",
        })
      }

      if(this.formFieldList.includes('datasource')){
        this.parseValueDatasource(field)
      }

      if(this.formFieldList.includes('default')){
       this.parseValueDefault(field)
      }
      this.configSchema = true
    },
    changeValueDefaultApi(){
      let newDefault = null
      if(this.fields.input_type == 'date' && this.modelDefaultType && this.modelRadioDate) {
        newDefault = {
          type: this.modelDefaultType,
          date: this.modelDefaultCustomDate || this.modelRadioDate
        }
      } else {
        newDefault = this.fields.default && this.fields.default.length ? this.fields.default : ''
      }

      this.fields = Object.assign({},
        {
          ...this.fields,
          default: newDefault
        }
      )
    },
    saveMenuListFieldSchema(key) {
      if(this.formFieldList.includes('default')){
        this.changeValueDefaultApi()
      }

      this.$emit('addOrEditItemSchema', {
        key,
        role: this.configSchemaRole,
        schemaName: this.currentSchema ? this.currentSchema.data.name : '',
        programName: this.programInfo.name,
        data: this.fields
      })
      this.clearData()
    },
    clearData(){
      this.configSchema = false
      this.fields = {}
      this.datasourceIsString = true
      this.datasourceList = []
      this.datasourceObject = {}
      this.isModalActive = false
      this.modelRadioDate = ''
      this.modelDefaultType = ''
      this.modelDefaultCustomDate = ''
    },
    openModalDeleteFieldSchema(field){
      Object.assign(this.modalDeleteSchemaField, {
        ...this.modalDeleteSchemaField,
        middleSlotContentText: `Deseja excluir este campo para o papel ${this.translateRoleType(this.configSchemaRole)} deste programa?`
      })
      this.isModalActive = true
      this.activeFieldToDelete = field
    },
    async deleteFieldSchemaForm(){
      if(this.formFieldList.includes('default')){
        this.activeFieldToDelete = Object.assign({}, {
          ...this.activeFieldToDelete,
          default: ''
        })
      }

      this.$emit('removeItemSchema', {
        field: this.activeFieldToDelete,
        role: this.configSchemaRole,
        schemaName: this.currentSchema ? this.currentSchema.data.name : '',
        programName: this.programInfo.name
      })
      this.clearData()
    },
    addFieldInSchema(item){
      this.fields = Object.assign({}, item)
      this.configSchema = true;
    }
  }
}
</script>

<style>
.list-asset-fields {
  display: flex!important;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
}

.v-list-item::after {
  content: none;
}

.menu-config-form-asset h3 {
  font-size: 1.024rem !important;
}
</style>

<template>
  <div>
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
    >
      <template v-slot:header-title>
        Tarefas Agendadas
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          max-width="600"
          @input="createScredule"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0" 
              v-on="on"
            >
              Adicionar
            </BaseButton>
          </template>
          <BaseModal
            :show-indeterminate-progress="modalSchedules.showIndeterminateProgress"
            :top-slot-content-title="modalSchedules.topSlotContentTitle"
            :remove-button-close="modalSchedules.removeButtonClose"
            :middle-slot-content-text-highlight="modalSchedules.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalSchedules.middleSlotContentText"
            :middle-check-agree="modalSchedules.middleCheckAgree"
            :bottom-buttons-align="modalSchedules.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalSchedules.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalSchedules.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalSchedules.removeButtonCancel"
            :remove-button-confirm="modalSchedules.removeButtonConfirm"
            :custom-enable-bottom-button-confirm-label="valid"
            @on-cancel="modalSchedules.onCancel"
            @on-confirm="submitFormSchedules"
          >
            <div style="margin: 0 auto">
              <v-form
                ref="formSchedules"
                v-model="valid"
              >
                <v-container>
                  <v-row dense>
                    <v-spacer />
                    <v-switch
                      id="qa-id-name-schedules-activated"
                      v-model="schedule.activated"
                      class="platform-organization-profile-account--switch mt-6"
                      color="primary"
                      :label="schedule.activated ? 'Ativado' : 'Desativado'"
                    />
                  </v-row>
                  <v-row dense>
                    <v-col 
                      cols="auto"
                      sm="12"
                    >
                      <v-select
                        v-model="schedule.initiator"
                        :items="getInitiators"
                        label="Originador *"
                        qa-identification-name="qa-id-name-schedule-initiator"
                        :rules="[isValid]"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col 
                      cols="auto"
                      sm="12"
                    >
                      <v-select
                        v-model="schedule.type"
                        :items="getEventTypes"
                        item-text="eventTypesList.type"
                        item-value="eventTypesList.type"
                        label="Tipo de Evento *"
                        qa-identification-name="qa-id-name-schedule-type"
                        :rules="[isValid]"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col 
                      cols="auto"
                      sm="12"
                    >
                      <BaseInput
                        ref="scheduleContext"
                        v-model="schedule.context"
                        :rules="[isValid]"
                        qa-identification-name="qa-id-name-schedule-context"
                        label="Contexto *"
                      />
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <h3 class="mt-3 font-weight-regular">
                      Expressão *
                    </h3>
                    <v-menu
                      v-model="menuInfo"
                      offset-x
                      top
                      :max-width="500"
                      :max-height="500"
                      content-class="menu-info"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <BaseButton
                          :has-icon="true"
                          v-on="on"
                        >
                          <v-icon
                            color="primary"
                          >
                            mdi-information
                          </v-icon>
                        </BaseButton>
                      </template>
                      <span>
                        <strong>Dicas:</strong> <br><br>
                        <strong>Second</strong>: 0 a 59 ou * ou intervalo <br>
                        <strong>Minute</strong>: 0 a 59 ou * ou intervalo<br>
                        <strong>Hour</strong>: 0 a 23 ou * ou intervalo<br>
                        <strong>Day</strong>: 1 a 31, ou ? ou intervalo<br>
                        <strong>Month</strong>: 0 a 11 ou os valores JAN, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV e DEC, ou *<br>
                        <strong>Week</strong>: um ou mais desses valores: SUN, MON, TUE, WED, THU, FRI e SAT, ou ?<br>
                        <strong>Year</strong>: um valor numerico, ou * ou intervalo<br><br>
                        O <strong>intervalo</strong> representa um <strong>*/<i>numero</i></strong>. O número sendo o valor do intervalo.<br><br>
                        Exemplos:<br><br>
                        */30 intervalo de 30 minutos (a cada 30 minutos)<br>
                        */10 a cada 10 minutos<br><br>
                        Links úteis para saber mais: <br><br>
                        <a 
                          href="https://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html" 
                          target="_blank"
                        >Tutorial Quartz
                        </a><br>
                        <a 
                          href="https://www.freeformatter.com/cron-expression-generator-quartz.html" 
                          target="_blank"
                        >Free Formatter
                        </a><br>
                        <a 
                          href="http://www.cronmaker.com/?1" 
                          target="_blank"
                        >Cron Maker
                        </a><br>
                      </span>
                      <div
                        style="display: flex; justify-content: end"
                      >
                        <BaseButton
                          color="primary"
                          @click="menuInfo = false"
                        >
                          Fechar
                        </BaseButton>
                      </div>
                    </v-menu>
                  </div>
                  <v-row dense>
                    <v-col
                      v-for="(field, index) in schedule.expression" 
                      :key="index"
                      style="min-width: calc(100% / 7); max-width: calc(100% / 7)"
                    >
                      <BaseInput
                        v-model="field.value"
                        :label="field.label"
                        class="mt-0"
                        :qa-identification-name="`qa-id-name-schedule-expression-${field.label}`"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <BaseDataTable
        :items="schedulesList"
        :headers="headers"
        :hide-actions="false"
        :has-infinite-pagination="true"
        :options="pagination"
        background-color="transparent"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td>
              {{ value.item.initiator }}
            </td>
            <td>
              {{ value.item.type }}
            </td>
            <td>
              {{ value.item.context }}
            </td>
            <td>
              {{ value.item.activated }}
            </td>
            <td>
              {{ value.item.expression }}
            </td>
            <td
              class="text-right"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="editSchedule(value.item)"
              >
                Editar
              </BaseButton>
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="modalDeleteSchedule(value.item.uuid)"
              >
                Apagar
              </BaseButton>
            </td>
          </tr>
          <v-dialog
            v-model="deleteScheduleModalActive"
            max-width="560"
            persistent
          >
            <BaseModal
              :show-indeterminate-progress="deleteScheduleModal.showIndeterminateProgress"
              :top-slot-content-title="deleteScheduleModal.topSlotContentTitle"
              :remove-button-close="deleteScheduleModal.removeButtonClose"
              :middle-slot-content-text-highlight="deleteScheduleModal.middleSlotContentTextHighlight"
              :middle-slot-content-text="deleteScheduleModal.middleSlotContentText"
              :bottom-buttons-align="deleteScheduleModal.bottomButtonsAlign"
              :bottom-slot-content-button-cancel-label="deleteScheduleModal.bottomSlotContentButtonCancelLabel"
              :bottom-slot-content-button-confirm-label="deleteScheduleModal.bottomSlotContentButtonConfirmLabel"
              :remove-button-cancel="deleteScheduleModal.removeButtonCancel"
              :remove-button-confirm="deleteScheduleModal.removeButtonConfirm"
              @on-cancel="() => deleteScheduleModalActive=false"
              @on-confirm="() => deleteScheduleModal.onConfirm()"
            />
          </v-dialog>
        </template>
      </BaseDataTable> 
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
    </BaseCard>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex/dist/vuex.common.js'
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'

export default {
  components: { BaseDataTable, BaseModal, BaseSnackbar },
  mixins: [ BaseSnackbarConfiguration ],
  data() {
    return {
      isModalActive: false,
      deleteScheduleModalActive: false,
      deleteScheduleModal: {},
      valid: false,
      menuInfo: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: "Originador", value: "initiator" },
        { text: "Tipo", value: "type" },
        { text: "Contexto", value: "context" },
        { text: "Ativa", value: "active"},
        { text: "Expressão", value: "expression"},
        { text: "", value: "actions",sortable: false }
      ],
      modalSchedules: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Adicionar Tarefa",
        removeButtonClose: true,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      modalDeleteSchedule(id){
        Object.assign(this.deleteScheduleModal, {
          showIndeterminateProgress: false,
          topSlotContentTitle: "Excluir tarefa",
          removeButtonClose: false,
          middleSlotContentTextHighlight: "",
          middleSlotContentText: `Tem certeza que deseja excluir essa tarefa agendada?`,
          bottomButtonsAlign: "right",
          bottomSlotContentButtonCancelLabel: "Fechar",
          bottomSlotContentButtonConfirmLabel: "Excluir",
          removeButtonCancel: false,
          removeButtonConfirm: false,
          onConfirm: () => this.deleteSchedule(id)
        })
        this.deleteScheduleModalActive = true
      },
      schedule: {
        uuid: '',
        initiator: '',
        type: '',
        context: '',
        activated: true,
        expression: [
          { label: "Second", value: "" },
          { label: "Minute", value: "" },
          { label: "Hour", value: "" },
          { label: "Day", value: "" },
          { label: "Month", value: "" },
          { label: "Week", value: "" },
          { label: "Year", value: "" },
        ],
      }
    }
  },

  computed: {
    ...mapGetters(['getInitiators', 'getEventTypes']),
    ...mapState({
      schedulesList: (state) => state.common.schedulesList,
    }),
  },

  created () {
    this.getSchedulesList()
  },

  methods: {
    ...mapActions(['apiGetSchedules', 'apiGetInitiators', 'apiGetEventTypes', 'apiCreateSchedules', 'apiDeleteSchedules', 'apiUpdateSchedules']),

    async getSchedulesList(){
      await this.apiGetSchedules()
    },

    async openModalActive(){
      await this.apiGetInitiators()
      await this.apiGetEventTypes()
      this.isModalActive = true
    },

    async editSchedule(value) {
      try {
        this.openModalActive()

        this.schedule.uuid = value.uuid
        this.schedule.activated = value.activated
        this.schedule.initiator = value.initiator
        this.schedule.type = value.type
        this.schedule.context = value.context.trim()

        const expressionParts = value.expression.split(" ")
        expressionParts.forEach((value, index) => {
          if(this.schedule.expression[index]){
            this.schedule.expression[index].value = value || ""
          }
        })

        this.modalSchedules.topSlotContentTitle = "Editar Tarefa"
        this.modalSchedules.bottomSlotContentButtonConfirmLabel = "Atualizar"
      } catch (e) {
        this.showFeedback({
          color: "error",
          message: "Erro ao buscar tarefa agendada!",
        })
      }
    },

    async createScredule() {
      this.openModalActive()

      this.schedule = {
        uuid: '',
        initiator: '',
        type: '',
        context: '',
        activated: true,
        expression: [
          { label: "Second", value: "" },
          { label: "Minute", value: "" },
          { label: "Hour", value: "" },
          { label: "Day", value: "" },
          { label: "Month", value: "" },
          { label: "Week", value: "" },
          { label: "Year", value: "" },
        ],
      }

      this.modalSchedules.topSlotContentTitle = "Adicionar Tarefa"
      this.modalSchedules.bottomSlotContentButtonConfirmLabel = "Salvar"
    },

    async submitFormSchedules(){
      try {
        const expressionString = this.schedule.expression.map(item => item.value).join(" ")
        const payload = {
            activated: this.schedule.activated == true ? "true" : "false",
            context: this.schedule.context.trim(),
            expression: expressionString,
            initiator: this.schedule.initiator,
            type: this.schedule.type
          }
        if(this.schedule.uuid){
          await this.apiUpdateSchedules({
            uuid: this.schedule.uuid,
            ...payload
          })
        } else {
          await this.apiCreateSchedules(payload)
        }

        this.showFeedback({
          message: this.schedule.uuid ? 'Tarefa agendada atualizada com sucesso!' : 'Tarefa agendada adicionada com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: this.schedule.uuid ? 'Erro ao atualizar tarefa agendada!' : 'Erro ao adicionar tarefa agendada!',
        })
      } finally {
        this.isModalActive = false
        this.getSchedulesList()
      }
    },

    async deleteSchedule(id){
      try {
        await this.apiDeleteSchedules({ uuid: id })

        this.showFeedback({
          message: 'Tarefa agendada removida com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao remover tarefa agendada!',
        })
      } finally {
        this.deleteScheduleModalActive = false
        this.getSchedulesList()
      }
    },

    closeModal() {
      this.isModalActive = false
    },
  }
}
</script>

<style scoped>
 .menu-info { 
  background-color: white;
  padding: 30px;
 }
</style>
import {resetForm} from '../utils'
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState({
      hideAllData: state => state.platformProgram.hideAllData
    }),
    ...mapGetters(['getMenuTitles'])
  },
  methods: {
    isValid(value) {
      return !!value || 'Campo obrigatório'
    },
    isOnlyNumber(value) {
      return !value || !String(value).match(/[^0-9]/g) || 'Número inválido'
    },
    isDigitNumber(value) {
      return !value || !String(value).match(/[^0-9]/g) || 'Ao invés de letras use o 0 (zero)'
    },
    isEmailValid(emailList) {
      let errorMessage = emailList.length == 0 ? ['Campo obrigatório'] : []
      emailList.filter(email => {
        if(!(!!String(email).match(/^([a-z0-9](?!.*\.\.)[a-z0-9.-]*[^A-Z., ])(@[a-z0-9_-]+)(\.[a-z0-9]+(\.[a-z0-9]+)?[^.,A-Z0-9_ \t])$/gm))) {
          errorMessage = ["Email inválido. Verifique o(s) email(s) inserido(s)."]
        }
      })

      return errorMessage
    },
    isValidBoolean (value) {
      return value != null || 'Campo obrigatório'
    },
    isValidArray(value) {
      return value.length != 0 || 'Campo obrigatório'
    },
    isAmountValid(value) {
      return this.parseNumberDecimal(value) > 0 || 'Valor inválido'
    },
    forceResetInputValue(instanceVue, field){
      const inputField = instanceVue.$refs[field]
      if(inputField && Array.isArray(inputField)){
        if(inputField[0]){
          inputField[0].$el.getElementsByTagName('input')[0].value = ''
        }
      } else if(inputField) {
        inputField.$el.getElementsByTagName('input')[0].value = ''
      }
    },
    removePercentageFromRate(rate) {
      return rate ? rate.replace(/\s%/, "") : ""
    },
    parseNumberDecimal(value) {
      return value ? parseFloat(value.replaceAll(".","").replace(",", ".")) : 0
    },
    isValidLengthForTax(value) {
      const taxValue = this.removePercentageFromRate(value)
      return !!value && taxValue.length <= 8 && this.parseNumberDecimal(taxValue) <= 100 && this.parseNumberDecimal(taxValue) > 0 || "Valor inválido"
    },
    cancelForm(instanceVue, name) {
      resetForm(instanceVue, name)
      this.$emit('onCancel')
    },
    getDiffDays(dates) {
      let timeDiff = Math.abs(new Date(dates[1]).getTime() - new Date(dates[0]).getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays
    },
    removeTabsForm(value) {
      for (const index in value) {
        if (typeof value[index] === 'string') {
          value[index] = value[index].replace(/\t/g, '')
        }
      }
    },
    hideData(value, isSensitive){
      if(this.hideAllData && isSensitive){
        return '●●●●'
      } else {
        return value
      }
    },
    formatFields(key, object, inputType) {
      const handlers = {
        'from.name': () => this.returnValueOfNestedField(key, object),
        'to.name': () => this.returnValueOfNestedField(key, object),
        'type.title': () => this.returnValueOfNestedField(key, object),
        'type.sub_type': () => this.returnValueOfNestedField(key, object),
        'type.external-type': () => this.returnValueOfNestedField(key, object),
        'status.name': () => this.returnValueOfNestedField(key, object),
        'from.tax-number': () => this.returnValueOfTaxNumberField(key, object),
        'to.tax-number': () => this.returnValueOfTaxNumberField(key, object),
        'parties': () => this.formatParties(key, object),
      }
      const handler = handlers[key]

      if(handlers[key]) {
        return handler()
      } else if (inputType === 'amount'){
        return this.formatCurrencyIfNecessary(key, object)
      } else if (inputType === 'date'){
        return this.formatDate(key, object)
      } else if(inputType === 'percent') {
        return this.formatTax(key, object)
      } else {
        return object[key]
      }
    },
    returnValueOfNestedField(key, object) {
      if(object[this.splitProp(key)[0]] && object[this.splitProp(key)[0]][this.splitProp(key)[1]]){
        return object[this.splitProp(key)[0]][this.splitProp(key)[1]]
      } else {
        return '-'
      }
    },
    returnValueOfTaxNumberField(key, object) {
      if(object[this.splitProp(key)[0]] && object[this.splitProp(key)[0]]['data'][this.splitProp(key)[1]]){
        return this.$options.filters.maskCPForCNPJ(object[this.splitProp(key)[0]]['data'][this.splitProp(key)[1]])
      } else {
        return '-'
      }
    },
    formatCurrencyIfNecessary(key, object){
      if(typeof object[key] === 'number') {
        object[key] = object[key].toString()
      }
      return object[key] && !object[key].includes(',') ? this.$options.filters.formatCurrency(object[key]) : object[key]
    },
    formatDate(key, object){
      return object[key] ? this.$options.filters.getDate(object[key]) : '-'
    },
    formatTax(key, object){
      return object[key] ? this.$options.filters.formatCurrency(this.convertToPercentage(object[key]), false, 4) + '%' : '-'
    },
    formatParties(key, object){
      return object[key] ?  object[key].map(p => p.name).join(', ') : '-'
    },
    convertToPercentage(value) {
      return parseFloat(value) * 100
    },
    convertToDecimal(value) {
      return value / 100
    },
    splitProp(key) {
      let splittedKey = key.split('.')
      return splittedKey
    },
    getTextMenu(route, text){
      let menu = this.getMenuTitles.filter(title => title.key === route)
      return menu[0] ? menu[0].name : text
    }
  }
}

<template>
  <div class="step-program-participants-documents">
    <div class="text-center">
      <h1 class="font-weight-bold title mb-1 mt-6 color--text-black-054">
        Documentos dos participantes
      </h1>
      <p class="mb-6 color--text-black-054 body-2">
        Configure quais documentos os participantes devem fornecer ao aceitar fazer parte do seu programa.
      </p>
    </div>
    <BaseDataTable
      v-if="dataDocumentTypeList.length > 0"
      :items="dataDocumentTypeList"
      :hide-actions="true"
      :hide-headers="true"
      :headers="filteredHeaders"
      background-color="transparent"
    >
      <template v-slot:inject-header="{headers}">
        <tr>
          <th
            v-for="(header, index) in headers.props.headers"
            :key="index"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>

      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="props in scopedItems"
          :key="props.index"
        >
          <td style="max-width: 230px">
            <p class="truncate-content-text">
              <v-icon class="mr-2">
                {{ getDocumentIcon(props.item.document_type.scope) }}
              </v-icon>
              <span>{{ props.item.document_type.name }}</span>
            </p>
          </td>
          <td>
            <v-row justify="center">
              <v-checkbox
                :input-value="props.item.buyer"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'buyer')"
              />
            </v-row>
          </td>
          <td>
            <v-row justify="center">
              <v-checkbox
                :input-value="props.item.trading_partner"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'trading_partner')"
              />
            </v-row>
          </td>
          <td>
            <v-row justify="center">
              <v-checkbox
                :input-value="props.item.seller"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'seller')"
              />
            </v-row>
          </td>
          <td>
            <v-row justify="center">
              <v-checkbox
                :input-value="props.item.is_signed"
                :disabled="disableIsSignedCheckbox(props.item.document_type.scope)"
                primary
                hide-details
                @change="changeCheckbox(props.index, 'is_signed')"
              />
            </v-row>
          </td>
          <td>
            <BaseButton
              type="text"
              :has-icon="true"
              color="primary"
              class="ma-0"
              @click="deleteDocumentType(props.item.document_type.id)"
            >
              <v-icon>delete</v-icon>
            </BaseButton>
          </td>
        </tr>
      </template>
    </BaseDataTable>
    <v-dialog
      v-model="isModalActive"
      max-width="560"
      persistent
    >
      <template v-slot:activator="{ on }">
        <BaseButton
          type="text"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
          :class="{'d-flex button-center': dataDocumentTypeList.length === 0}"
          v-on="on"
        >
          Adicionar Documento
        </BaseButton>
      </template>
      <BaseModal
        :show-indeterminate-progress="modalAddDocument.showIndeterminateProgress"
        :top-slot-content-title="modalAddDocument.topSlotContentTitle"
        :remove-button-close="modalAddDocument.removeButtonClose"
        :middle-slot-content-text-highlight="modalAddDocument.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalAddDocument.middleSlotContentText"
        :middle-check-agree="modalAddDocument.middleCheckAgree"
        :bottom-buttons-align="modalAddDocument.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalAddDocument.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalAddDocument.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalAddDocument.removeButtonCancel"
        :remove-button-confirm="modalAddDocument.removeButtonConfirm"
        :custom-enable-bottom-button-confirm-label="valid"
        @on-cancel="modalAddDocument.onCancel"
        @on-confirm="addProgramDocumentRequired"
      >
        <template v-slot:inject-form>
          <v-container class="pa-0">
            <v-form
              ref="formAddDocument"
              v-model="valid"
            >
              <v-row>
                <v-col
                  sm="5"
                  class="pb-0 pl-0"
                >
                  <v-select
                    v-model="selectedScope"
                    :items="typeOptions"
                    item-text="text"
                    item-value="value"
                    label="Tipo"
                    :rules="[isValid]"
                    required
                    validate-on-blur
                    @change="getDocumentOptionsByScope"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  sm="6"
                  class="pb-0 pl-0"
                >
                  <v-select
                    v-model="selectedDocument"
                    :items="documentOptions"
                    :disabled="documentOptions.length == 0"
                    item-text="name"
                    item-value="id"
                    label="Documento"
                    :rules="[isValid]"
                    required
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
      </BaseModal>
    </v-dialog>

    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState} from 'vuex'
import BaseActionFooter from '../../BaseActionFooter'
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'

export default {
  components: {BaseActionFooter, BaseDataTable, BaseModal},
  data() {
    return {
      isModalActive: false,
      modalAddDocument: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Adicionar documento",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: `<p>Configure um novo documento.</p>`,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      typeOptions:[
        { text: 'Pessoa Física', value: 'person'},
        { text: 'Pessoa Jurídica', value: 'organization'},
        { text: 'Programa', value: 'program'}
      ],
      documentOptions: [],
      selectedScope: null,
      selectedDocument: null,
      valid: false,
      headers: [
        {
          text: 'Documento',
          value: 'name',
          class: 'name',
        },
        {
          text: '',
          value: 'buyer',
          class: 'buyer text-center',
        },
        {
          text: '',
          value: 'trading-partner',
          class: 'trading-partner text-center',
        },
        {
          text: '',
          value: 'seller',
          class: 'seller text-center'
        },
        {
          text: 'Formalizar',
          value: 'is_signed',
          class: 'is_signed text-center'
        },
        {
          text: '',
          value: 'delete',
          class: 'delete'
        }
      ],
      backButton: {
        action: ()=>{ this.$emit("onPreviousStep", 3) },
        text: 'Voltar',
        disabled: false
      },
      cancelButton: {
        action: ()=>{ this.$router.push('/platform-home') },
        text: 'Cancelar',
        disabled: false
      },
      confirmButton: {
        action: this.nextStep,
        text: 'Avançar',
        disabled: true
      }
    }
  },
  computed:{
    ...mapState({
      programId: (state) => {
        return state.platformProgram.programId
      },
      programSelectedRoles: (state) => {
        return state.platformProgram.programSelectedRoles
      },
      documentTypeList: (state) => {
        return state.platformProgram.programDocumentTypes
      }
    }),
    dataDocumentTypeList() {
      return this.documentTypeList.map(element => {
        const { id, roles, is_signed, template, document_type } = element
        return {
          id: id,
          document_type: document_type,
          buyer: this.searchInList(roles, "buyer"),
          trading_partner: this.searchInList(roles, "trading-partner"),
          seller: this.searchInList(roles, "seller"),
          is_signed: is_signed,
          template: template
        }
      })
    },
    filteredHeaders() {
      return this.headers.map(elem => {
        this.programSelectedRoles.find(e => {
          if(e.type === elem.value){
            elem.text = e.description
          }
        })
        return elem
      })
    }

  },
  watch: {
    dataDocumentTypeList: {
      handler() {
        this.confirmButton.disabled = this.hasEmptyRole()
      },
      deep: true
    }
  },
  async created() {
    if(this.programId){
      await this.apiGetProgramSelectedRoles(this.programId)
      await this.apiGetProgramDocumentTypes(this.programId)
    }
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setUseActionFooter"]),
    ...mapActions(['apiGetProgramDocumentTypes', 'apiGetProgramSelectedRoles',
    'apiGetDocumentOptionsByScope', 'apiAddProgramDocumentRequired', 'apiUpdateProgramDocumentRequired',
    'apiDeleteProgramDocumentRequired', 'apiGetProgramDocuments']),
    async getDocumentOptionsByScope() {
      this.documentOptions = await this.apiGetDocumentOptionsByScope({scope: this.selectedScope})
    },
    closeModal(){
      this.$refs.formAddDocument.reset();
      this.isModalActive = false,
      this.selectedScope = null,
      this.selectedDocument = null
    },
    hasEmptyRole(){
      return this.dataDocumentTypeList.find(e => e.buyer == false && e.trading_partner == false && e.seller == false) ? true : false
    },
    async nextStep(){
      await this.apiGetProgramDocuments(this.programId)
      await this.apiGetProgramSelectedRoles(this.programId)
      this.$emit("onNextStep", 5)
    },
    async addProgramDocumentRequired(){
      const data = {
        programId: this.programId,
        id: "",
        document_type: {
          id: this.selectedDocument,
          name: "",
          description: "",
          scope: ""
        },
        roles: "",
        is_signed: "",
        template_id: ""
      }
      try {
        await this.apiAddProgramDocumentRequired(data)
        this.getDocumentTypes()
        this.closeModal()
      } catch (e) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao adicionar documento.',
          color: 'error',
        })
      }
    },
    searchInList(list, elem){
      return list.indexOf(elem) > -1 ? true : false
    },
    changeCheckbox(index, field){
      this.dataDocumentTypeList[index][field]= !this.dataDocumentTypeList[index][field]
      this.updateProgramDocumentRequired(this.dataDocumentTypeList[index])
    },
    async updateProgramDocumentRequired(item){
      const data = this.dataDocumentTypeListForAPI(item)
      await this.apiUpdateProgramDocumentRequired(data)
      this.getDocumentTypes()
    },
    dataDocumentTypeListForAPI(item) {
      const { id, buyer, trading_partner, seller, is_signed, template, document_type } = item
      return {
        programId: this.programId,
        id: id,
        document_type: document_type,
        roles: this.roleString({buyer: buyer, "trading-partner": trading_partner, seller: seller}),
        is_signed: is_signed,
        template_id: template
      }
    },
    roleString(ob){
      let string = ''
      for(let key in ob){
        if(ob[key]){
          string += `${key},`
        }
      }
      return string.substring(0, string.length - 1)
    },
    async getDocumentTypes() {
      await this.apiGetProgramDocumentTypes(this.programId)
    },
    getDocumentIcon(scope){
      switch (scope) {
        case "program":
          return "tv"
        case "person":
          return "person"
        case "organization":
          return "domain"
      }
    },
    disableIsSignedCheckbox(scope){
      return scope === 'person' || scope === 'organization'
    },
    async deleteDocumentType(typeId) {
      try {
        await this.apiDeleteProgramDocumentRequired({programId: this.programId, typeId: typeId})
        this.getDocumentTypes()
      } catch (e) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao apagar documento.',
          color: 'error',
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style lang="scss">
.step-program-participants-documents {
  max-width: 820px;
  margin: 0 auto;

  .v-text-field__details {
    display: none;
  }
  .button-center {
    margin: 0 auto;
  }

  .base-data-table {
    input {
      font-size: 0.8125rem
    }
    tr > td {
      height: 57px!important;
      color: var(--custom-theme-black-054) !important;
    }
    th {
      font-weight: 600 !important;
    }
    tr > td, tr > th {
      padding: 0px 10px !important;
    }
    tr > td:first-child, tr > td:last-child, tr > th:first-child, tr > th:last-child {
      padding: 0px 7px !important;
    }
    .trading-partner {
      width: 115px;
    }
    .delete {
      width: 40px;
    }
  }
}
</style>

import api from '../../services/api';

export default {
  state: {
    initiators: [],
    eventProcessorsList: [],
    schedulesList: [],
    eventTypesList: [],
    rulesList: []
  },
  getters: {
    getInitiators(state) {
      return [...state.initiators.map(
        elem => elem.name
      )].sort((a, b) => a.localeCompare(b))
    },
    getEventTypes(state) {
      return [...state.eventTypesList.map(
        elem => elem.type
      )].sort((a, b) => a.localeCompare(b))
    }
  },
  mutations: {
    setInitiators(state, payload) {
      state.initiators = payload;
    },
    setEventProcessors(state, payload){
      state.eventProcessorsList = payload
    },
    setSchedules(state, payload) {
      state.schedulesList = payload
    },
    setEventTypes(state, payload) {
      state.eventTypesList = payload
    },
    setRules(state, payload){
      state.rulesList = payload
    }
  },
  actions: {
    apiGetInitiators({commit}) {
      return new Promise((resolve, reject) => {
        api.get('initiators')
        .then((data) => {
          commit('setInitiators', data.data)
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async apiCreateInitiator({commit}, payload) {
      await api.post('/initiators', payload)
    },
    async apiUpdateInitiator({commit}, payload) {
      const {uuid, ...data} = payload
      await api.put(`/initiators/${uuid}`, data)
    },
    async apiDeleteInitiator(context, payload) {
      await api.delete(`/initiators/${payload}`)
    },
    async apiGetEventProcessors({ commit }) {
      const result = await api.get(`/event_processors`)
      commit('setEventProcessors', result.data)
    },
    async apiCreateEventProcessors({commit}, payload){
      await api.post(`/event_processors`, payload)
    },
    async apiDeleteEventProcessors({commit}, payload){
      await api.delete(`/event_processors/${payload.name}`)
    },
    async apiGetSchedules({commit}) {
      const result = await api.get(`/schedules`)
      commit("setSchedules", result.data)
    },
    async apiCreateSchedules({commit}, payload){
      await api.post(`/schedules`, payload)
    },
    async apiUpdateSchedules({commit}, payload) {
      await api.put(`/schedules/${payload.uuid}`, payload)
    },
    async apiDeleteSchedules({commit}, payload){
      await api.delete(`/schedules/${payload.uuid}`)
    },
    async apiGetEventTypes({commit}){
      const result = await api.get(`/event_types`)
      commit("setEventTypes", result.data)
    },
    async apiCreateEventType({commit}, payload){
      await api.post(`/event_types`, payload)
    },
    async apiDeleteEventType({commit}, payload){
      await api.delete(`/event_types/${payload.type}`)
    },
    async apiGetRules({commit}, payload){
      const result = await api.get(`/rules`, { params: payload })
      commit("setRules", result.data)
      return result
    },
    async apiSimulateTest({commit}, payload){
      const result = await api.post(`/rules/simulate`, payload) 
      return result
    },
    async apiClearCache({commit}, payload){
      await api.post(`/rules/cache/clear`, payload) 
    }
  }
}

<template>
  <div class="my-file-list">
    <BaseContainer
      :permission-list="['trading-partner', 'owner', 'buyer', 'seller']"
    >
      <template v-slot:header-title>
        {{ getTextMenu('files','Meus arquivos') }}
      </template>
      <template v-slot:header-title-description>
        Faça upload de arquivos e cadastros manuais ou download de relatórios disponíveis em seu programa.
      </template>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="my-file-list--tab mt-2"
      >
        <v-tab>Importar</v-tab>
        <v-tab>Relatórios</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="background:transparent;"
      >
        <v-tab-item class="pt-6">
          <UploadedFilesList />
        </v-tab-item>
        <v-tab-item class="pt-6">
          <ReportFilesList />
        </v-tab-item>
      </v-tabs-items>
    </BaseContainer>
  </div>
</template>

<script>

import BaseContainer from '../../BaseContainer'
import UploadedFilesList from './UploadedFilesList'
import ReportFilesList from './ReportFilesList'

export default {
  components: {BaseContainer, UploadedFilesList, ReportFilesList},
  data: () => ({
    tab: null
  }),
  created(){
    if(this.$route.params.context === 'reports'){
      this.tab = 1
    }
  }
}
</script>
<style scoped>
.v-tab {
  font-weight: 700;
  font-size: 14px;
}
</style>

<template>
  <BaseCard
    :has-divider="true"
    :has-main-container="true"
    base-card-title-class="app-font-semibold title mb-1"
  >
    <template v-slot:header-title>
      Processadores
    </template>
    <template v-slot:header-actions>
      <v-dialog
        :value="isModalActive"
        persistent
        max-width="600"
        @input="createEventProcessors"
      >
        <template v-slot:activator="{ on }">
          <BaseButton
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
            v-on="on"
          >
            Adicionar
          </BaseButton>
        </template>
        <BaseModal
          :show-indeterminate-progress="modalEventProcessors.showIndeterminateProgress"
          :top-slot-content-title="modalEventProcessors.topSlotContentTitle"
          :remove-button-close="modalEventProcessors.removeButtonClose"
          :middle-slot-content-text-highlight="modalEventProcessors.middleSlotContentTextHighlight"
          :middle-slot-content-text="modalEventProcessors.middleSlotContentText"
          :middle-check-agree="modalEventProcessors.middleCheckAgree"
          :bottom-buttons-align="modalEventProcessors.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="modalEventProcessors.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="modalEventProcessors.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="modalEventProcessors.removeButtonCancel"
          :remove-button-confirm="modalEventProcessors.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="valid"
          @on-cancel="modalEventProcessors.onCancel"
          @on-confirm="submitFormEventProcessors"
        >
          <div style="margin: 0 auto;">
            <v-form
              ref="formEventProcessors"
              v-model="valid"
            >
              <v-container>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventProcessors.name"
                      :rules="[isValid]"
                      qa-identification-name="qa-id-name-eventProcessors-nome"
                      label="Nome *"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventProcessors.description"
                      :rules="[isValid]"
                      qa-identification-name="qa-id-name-eventProcessors-description"
                      label="Descrição *"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventProcessors.allowedEventTypes"
                      hint="Exemplo: (onCreate|afterCreate)"
                      qa-identification-name="qa-id-name-eventProcessors-allowedEventTypes"
                      label="Tipos de eventos aceitos"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="auto"
                    sm="12"
                  >
                    <BaseInput
                      v-model="eventProcessors.queueName"
                      :rules="[isValid]"
                      qa-identification-name="qa-id-name-eventProcessors-queueName"
                      label="Fila *"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </BaseModal>
      </v-dialog>
    </template>
    <v-col sm="3">
      <BaseInput
        ref="filter"
        v-model="filterName"
        label="Filtrar"
      />
    </v-col>
    <BaseDataTable
      :items="filterEventProcessorsList"
      :headers="headers"
      :hide-actions="false"
      :has-infinite-pagination="true"
      :options="pagination"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="value in scopedItems"
          :key="value.index"
        >
          <td>
            {{ value.item.name }}
          </td>
          <td>
            <BaseTextTooltip
              :text-item="value.item.description || '-'"
              width-item="350"
            />
          </td>
          <td>
            {{ value.item.queueName }}
          </td>
          <td>
            {{ value.item.allowedEventTypes }}
          </td>
          <td
            class="text-right"
          >
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              @click="modalDeleteEventProcessors(value.item.name)"
            >
              Apagar
            </BaseButton>
          </td>
        </tr>
        <v-dialog
          v-model="deleteEventProcessorsModalActive"
          max-width="560"
          persistent
        >
          <BaseModal
            :show-indeterminate-progress="deleteEventProcessorsModal.showIndeterminateProgress"
            :top-slot-content-title="deleteEventProcessorsModal.topSlotContentTitle"
            :remove-button-close="deleteEventProcessorsModal.removeButtonClose"
            :middle-slot-content-text-highlight="deleteEventProcessorsModal.middleSlotContentTextHighlight"
            :middle-slot-content-text="deleteEventProcessorsModal.middleSlotContentText"
            :bottom-buttons-align="deleteEventProcessorsModal.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="deleteEventProcessorsModal.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="deleteEventProcessorsModal.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="deleteEventProcessorsModal.removeButtonCancel"
            :remove-button-confirm="deleteEventProcessorsModal.removeButtonConfirm"
            @on-cancel="() => deleteEventProcessorsModalActive=false"
            @on-confirm="() => deleteEventProcessorsModal.onConfirm()"
          />
        </v-dialog>
      </template>
    </BaseDataTable>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </BaseCard>
</template>
<script>
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'
import BaseTextTooltip from '../../BaseTextTooltip'
export default {
  components: { BaseDataTable, BaseModal, BaseSnackbar, BaseTextTooltip },
  mixins: [ BaseSnackbarConfiguration ],
  data() {
    return {
      valid: false,
      isModalActive: false,
      deleteEventProcessorsModalActive: false,
      deleteEventProcessorsModal: {},
      filterName: '',
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy:['name']
      },
      headers: [
        { text: "Nome", value: "name" },
        { text: "Descrição", value: "description" },
        { text: "Fila", value: "queueName" },
        { text: "Tipo de Evento", value: "eventType" },
        { text: "", value: "actions", sortable: false}
      ],
      eventProcessors: {
        name: "",
        description: "",
        queueName: "",
        allowedEventTypes: ""
      },
      modalEventProcessors: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Processadores",
        removeButtonClose: true,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      modalDeleteEventProcessors(type){
        Object.assign(this.deleteEventProcessorsModal, {
          showIndeterminateProgress: false,
          topSlotContentTitle: "Excluir Processador",
          removeButtonClose: false,
          middleSlotContentTextHighlight: "",
          middleSlotContentText: `Tem certeza que deseja excluir esse Processador?`,
          bottomButtonsAlign: "right",
          bottomSlotContentButtonCancelLabel: "Fechar",
          bottomSlotContentButtonConfirmLabel: "Excluir",
          removeButtonCancel: false,
          removeButtonConfirm: false,
          onConfirm: () => this.deleteEventProcessors(type)
        })
        this.deleteEventProcessorsModalActive = true
      },
    }
  },
  computed: {
    ...mapState({
      eventProcessorsList: state => state.common.eventProcessorsList
    }),

    filterEventProcessorsList() {
      if (!this.filterName) {
        return this.eventProcessorsList
      } else {
        return this.eventProcessorsList.filter(eventProcessor =>
          eventProcessor.name.toLowerCase().includes(this.filterName.toLowerCase())
        )
      }
    }
  },
  async created(){
    await this.apiGetEventProcessors()
  },
  methods: {
    ...mapActions(['apiGetEventProcessors', 'apiCreateEventProcessors', 'apiDeleteEventProcessors']),

    createEventProcessors() {
      this.isModalActive = true
      this.eventProcessors = {
        name: "",
        description: "",
        queueName: "",
        allowedEventTypes: ""
      }
    },

    async submitFormEventProcessors(){
      try {
        const payload = {
          name: this.eventProcessors.name,
          description: this.eventProcessors.description,
          queueName: this.eventProcessors.queueName,
          allowedEventTypes: this.eventProcessors.allowedEventTypes
        }
        
        await this.apiCreateEventProcessors(payload)
        this.showFeedback({
          message: 'Tipo de evento criado com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar tipo de evento!',
        })
      } finally {
        this.isModalActive = false
        await this.apiGetEventProcessors()
      }
    },

    async deleteEventProcessors(value){
      try {
        await this.apiDeleteEventProcessors({ name: value })
        this.showFeedback({
          message: 'Tipo de evento removido com sucesso!',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          color: "error",
          message: 'Erro ao remover tipo de evento!',
        })
      } finally {
        this.deleteEventProcessorsModalActive = false
        await this.apiGetEventProcessors()
      }
    },

    closeModal(){
      this.isModalActive = false
    }
  }
}
</script>
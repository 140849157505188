<template>
  <div class="operation-info">
    <BaseContainer>
      <template slot="header-actions">
        <a
          class="headline font-weight-regular text-none text--primary"
          style="display: inline-block;"
          @click="onClickButtonBackOperationRegister"
        >
          <v-icon
            class="text--primary"
            style="vertical-align: middle;"
          >arrow_back</v-icon>
          <span
            class="pl-2"
            style="display: inline-block; vertical-align: middle;"
          >Voltar</span>
        </a>
      </template>
      <OperationInfoStepDetail 
        ref="stepDetail"
      />
      <OperationData
        :operation-disable-event-click="true"
        :operation-hide-actions="true"
        :operation-list-register-execution="operationOverview"
      />
      <DiagramOperation />
    </BaseContainer>
  </div>
</template>

<script>
import OperationData from './OperationData'
import OperationInfoStepDetail from './OperationInfoStepDetail'
import DiagramOperation from './DiagramOperation'
import { mapGetters } from 'vuex'

export default {
  name: "OperationInfo",
  components: { OperationData, DiagramOperation, OperationInfoStepDetail },
  methods: {
    onClickButtonBackOperationRegister(){
      this.$refs.stepDetail.closeOperationInfoStepDetail()
      this.$router.push('/operation-register')
      this.$destroy()
    }
  },
  computed: {
    ...mapGetters(['getCurrentRegisterOperation']),
    operationOverview(){
      return this.getCurrentRegisterOperation ? [ this.getCurrentRegisterOperation ] : []
    },
  }
}
</script>

<style></style>

<template>
  <div class="step-name-configuration">
    <h2 class="app-headline font-weight-bold title text-center mb-5">
      Configurações iniciais
    </h2>
    <v-form
      v-model="programConfiguration.valid"
    >
      <div class="mb-10">
        <h4 class="app-headline app-font-semibold step-name-configuration__title mb-4">
          <v-icon>tv</v-icon>
          <span class="ml-2">Nome do programa</span>
          <p class="body-1 mt-1">
            Dê um nome ao seu programa e faça uma breve descrição.
          </p>
        </h4>
        <v-col
          sm="8"
          class="pa-0 pl-2"
        >
          <BaseInput
            ref="partnerName"
            v-model="programConfiguration.name"
            label="Nome do programa*"
            :rules="[isValid]"
            ref-name="partnerName"
          />
        </v-col>
        <v-col
          sm="8"
          class="pa-0 pl-2"
        >
          <v-textarea
            v-model="programConfiguration.description"
            :rules="[isValid]"
            label="Descrição*"
            auto-grow
            rows="1"
          />
        </v-col>
      </div>

      <div
        v-if="userIsAdmin"
        class="mb-10"
      >
        <h4 class="app-headline app-font-semibold step-name-configuration__title mb-4">
          <v-icon>account_circle</v-icon>
          <span class="ml-2">Dono do programa</span>
          <p class="body-1 mt-1">
            Informe quem são o(s) dono(s) do programa.
          </p>
        </h4>
        <v-combobox
          v-model="modelOwnerEmailList"
          :items="ownerEmailList"
          :error-messages="errorMessage"
          label="Email(s)"
          hint="Ao digitar cada email aperte 'enter' para confirmar."
          persistent-hint
          multiple
          chips
          class="step-name-configuration_combobox"
          @change="validateEmailList(modelOwnerEmailList)"
        />
      </div>

      <div class="mb-10">
        <h4 class="app-headline app-font-semibold step-name-configuration__title mb-4">
          <v-icon>insert_link</v-icon>
          <span class="ml-2">Endereço (URL)</span>
          <p
            v-if="!isEditingProgram"
            class="body-1 mt-1"
          >
            Defina o endereço pelo qual o seu programa vai ser acessado.
          </p>
          <p
            v-else
            class="body-1 mt-1"
          >
            {{ programConfiguration.subdomain }}
          </p>
        </h4>
        <BaseInput
          v-if="!isEditingProgram"
          ref="partnerName"
          v-model="programConfiguration.subdomain"
          label="meuprograma*"
          :rules="[isValid, validateURLProgram]"
          ref-name="partnerName"
          class="d-inline-block mr-2 ml-2"
          :error-messages="errorMessageWhenChooseUri"
          @blur="verifyUri"
          @focus="() => errorMessageWhenChooseUri = ''"
        />
        <span v-if="!isEditingProgram">.{{ getHostName }}</span>
      </div>

      <div class="mb-10">
        <h4 class="app-headline app-font-semibold step-name-configuration__title mb-4">
          <v-icon>image</v-icon>
          <span class="ml-2">Logotipo do programa</span>
          <p class="body-1 mt-1 mb-4">
            Envie a marca da sua empresa ou logotipo do programa nos formatos jpg ou png.
          </p>
          <div class="d-inline">
            <img
              v-if="logoUrl"
              :src="logoUrl"
              alt="Logo do programa"
              width="25%"
            >
          </div>
          <div v-if="!hasUploadedLogo">
            <BaseFileInput
              primary-text="Escolher arquivo"
              sub-text="Altura recomendada: 30px."
              error-text="Envie um logo no formato .jpg ou .png."
              id-input="logo"
              :show-loader="isUploadingLogo"
              accept-types=".png,.jpg,.jpeg"
              :rules-types="['image/jpeg', 'image/jpg', 'image/png']"
              @uploadFileInput="uploadLogo"
            />
          </div>
          <BaseButton
            v-else
            type="text"
            color="error"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
            @click="deleteLogo"
          >
            Excluir
          </BaseButton>
        </h4>
      </div>
    </v-form>
    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import permissions from '../../../mixins/permissions'
import {utilsShowErrorInDevelopmentEnvironment} from '../../../utils'
import BaseActionFooter from '../../BaseActionFooter'
import BaseFileInput from '../../BaseFileInput'

export default {
  name: 'StepProgramName',
  components: { BaseActionFooter, BaseFileInput },
  mixins: [permissions],
  data() {
    return {
      modelOwnerEmailList: [],
      ownerEmailList: [],
      errorMessage: [],
      errorMessageWhenChooseUri: '',
      isUploadingLogo: false,
      logoUploaded: false,
      logoUrl: null,
      logoFile: null,
      hasUploadedLogo: false,
      programConfiguration: {
        valid: false,
        name: '',
        description: '',
        subdomain: '',
        template_id: null,
        programId: '',
        logo_uri: ''
      },
      backButton: {
        action: () => {
          this.isEditingProgram ? this.$router.push('/platform-home') :
          this.$router.push('/platform-configure-program')
        },
        text: 'Voltar'
      },
      cancelButton: {
        action: () => {
          this.$router.push('/platform-home')
        },
        text: 'Cancelar',
      },
      isEditingProgram: false
    }
  },
  computed: {
    ...mapState({
      programId: (state) => {
        return state.platformProgram.programId
      },
      selectedProgram: (state) => {
        return state.platformProgram.selectedProgram
      },
      programOwners: state => state.platformProgram.programOwners,
      programList: (state) => state.platformProgram.allPrograms
    }),
    confirmButton() {
      return {
        action: this.sendProgramConfiguration,
        text: 'Avançar',
        disabled: !this.programConfiguration.valid
      }
    },
    getHostName() {
      return window.location.hostname
    }
  },
  async created() {
    this.programConfiguration.programId = this.programId
    if(this.programConfiguration.programId){

      if(!this.selectedProgram){
        await this.apiGetAllPrograms()
        let program = this.programList.filter(elem => elem.id === this.programId)[0]
        this.setSelectedProgram(program)
      }

      this.setProgramInitiator(this.selectedProgram.initiator)
      this.isEditingProgram = true
      this.getProgram()
      await this.apiGetProgramOwners(this.programId)
      this.modelOwnerEmailList = this.programOwners
      this.ownerEmailList = this.programOwners
    }
  },
  methods: {
    ...mapActions(['apiCreateProgram', 'apiGetCheckProgramUri', 'apiGetSelectedAssetTypes', 'apiGetAssetTypes','apiUploadProgramLogo', 'apiUpdateProgramAssetList', 'apiSendProgramConfiguration', 'apiSendProgramLogo', 'apiGetProgramLogo', 'apiGetProgramOwners', 'apiUpdateProgramOwners', 'apiGetAllPrograms']),
    ...mapMutations(['setProgramInitiator', 'setSelectedProgram']),
    validateURLProgram(value) {
      return !!String(value).match(/^[a-zA-Z0-9-]*$/gm) || 'URL inválida'
    },
    validateEmailList(emailList){
      this.errorMessage = this.isEmailValid(emailList)
    },
    async getProgram(){
      const { name, description, host, id, logo_uri, initiator } = this.selectedProgram
      const idLogo = logo_uri.replace('storage://','')

      Object.assign(this.programConfiguration, {
        valid: true,
        name,
        description,
        subdomain: host,
        template_id: null,
        programId: id,
        logo_uri
      })

      try {
        this.logoUrl = await this.apiGetProgramLogo({programInitiator: initiator, id: idLogo})
        this.hasUploadedLogo = true
      } catch(e) {
        this.hasUploadedLogo = false
        this.logoUrl = null
      }

    },
    async uploadLogo(file) {
      this.isUploadingLogo = true
      let formData = new FormData();

      if (file) {
        formData.append('file', file);
        formData.append('content-type', file.type);
        this.logoFile = formData
        try {
          this.hasUploadedLogo = true
          this.logoUrl = URL.createObjectURL(file)
          this.logoUploaded = true
        } catch (e) {
          this.hasUploadedLogo = false
          this.$emit("onSubmitForm", {
            message: 'Erro ao enviar logo.',
            color: 'error'
          })
          utilsShowErrorInDevelopmentEnvironment(e)
        } finally {
          this.isUploadingLogo = false
        }
      }
    },
    deleteLogo() {
      this.hasUploadedLogo = false
      this.logoUrl = null
    },
    async verifyUri() {
      Object.assign(this.programConfiguration, {
        ...this.programConfiguration,
        subdomain: this.programConfiguration.subdomain.toLowerCase()
      })
      const result = await this.apiGetCheckProgramUri(this.programConfiguration.subdomain)
      if (result == 200) {
        this.errorMessageWhenChooseUri = 'Esse endereço não está disponível'
      }
      this.setProgramInitiator(this.programConfiguration.subdomain.toUpperCase())
    },
    async sendProgramConfiguration() {
      try {
        if(!this.isEditingProgram) {
          const {valid, ...data} = this.programConfiguration
          await this.apiCreateProgram(data)
        }

        await this.getLogoIdAndUpdateProgram()
        if(this.modelOwnerEmailList){
          await this.apiUpdateProgramOwners({
            programId: this.programId,
            data: this.modelOwnerEmailList
          })
        }
        await this.apiGetAssetTypes()

        this.logoUploaded = false
        this.$emit('onNextStep', 2);
      } catch (err) {
        this.$emit('onSubmitForm', {
          message: `Erro ao ${ this.isEditingProgram ? 'editar' : 'cadastrar'} programa`,
          color: 'error',
        })
        utilsShowErrorInDevelopmentEnvironment(err)
      }
    },
    async getLogoIdAndUpdateProgram() {
      const {valid, subdomain, template_id, ...data} = this.programConfiguration

      if(this.logoUploaded){
        const logo_uri = await this.sendProgramLogo(this.programId)
         await this.apiSendProgramConfiguration({...data, programId: this.programId, logo_uri: `storage://${logo_uri}`})
         this.programConfiguration.logo_uri = `storage://${logo_uri}`
      } else {
        await this.apiSendProgramConfiguration({...data, programId: this.programId})
      }
    },
    async sendProgramLogo(programId) {
      if(this.logoFile){
        const response = await this.apiSendProgramLogo({id: programId, file: this.logoFile})
        return response
      }
    }
  },
}
</script>

<style scoped>
.step-name-configuration {
  width: 560px;
  margin: 0 auto;
}

.step-name-configuration__title {
  font-size: 18px;
}

.step-name-configuration_combobox >>> .v-input__icon {
  display: none!important;
}
</style>
